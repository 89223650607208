import React, { useEffect, useState } from "react";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  addFollowers,
  fetchFollowSugg,
  ioiuserSelector,
  unFollow,
} from "../../api/ioiUser";
import { useDebounce } from "use-debounce";
import { FormControl } from "@mui/material";
import Axios from "axios";
import { keyUri } from "../../key";
import { useNavigate } from "react-router";
import {
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@mui/joy";

export default function Tab2({ user, token, type }) {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState();
  const [data, setData] = useState([]);
  const [debouncedText] = useDebounce(search, 1000);
  const { follow_sug } = useSelector(ioiuserSelector);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const userId = user?._id;
  const sub_categories = [
    ...Subcategory,
    ...Subcategory2,
    ...Subcategory3,
    ...Subcategory4,
  ];

  const [category, setCategory] = useState({
    category: "",
  });

  useEffect(() => {
    setData(follow_sug);
  }, [follow_sug]);

  useEffect(() => {
    dispatch(fetchFollowSugg(user?._id));
  }, [userId]);

  useEffect(() => {
    filter && debouncedText !== ""
      ? setData(
          filter.filter((item) => {
            return item._id !== userId;
          })
        )
      : setData(follow_sug);
  }, [filter, debouncedText, userId]);

  useEffect(() => {
    if (debouncedText !== "") {
      Axios.get(
        keyUri.BACKEND_URI + `/users/all-ioi?search=${debouncedText}`
      ).then(({ data }) => {
        setFilter(data?.ioiUsers);
      });
    }
    // setLoading(false)
  }, [dispatch, debouncedText]);

  useEffect(() => {
    
    if (debouncedText != "") {
      if (category.category != "") {
        setData([]);
        setCategory({ category: "" });
      }
    }
  }, [debouncedText]);

  const handleFormCategoryChange = (sub_category) => {
    ////console.log(sub_category.value);
    setCategory({ category: sub_category.value });
  };


  
  useEffect(() => {
    setData(
      follow_sug.filter((item) => {
        return item._id !== userId && item?.sub_category == category.category;
      })
    );
  }, [category.category]);

  ////console.log(type);

  return (
    <div>
      <section style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div className="w-full mb-8 lg:flex justify-end">
            <FormControl className="">
              <Autocomplete
                className="mb-2 lg:mr-[15px] md:mr-[15px]"
                disablePortal
                id="combo-box-demo"
                placeholder="Select Category"
                disableClearable
                options={sub_categories}
                name="category"
                onChange={(event, newValue) => {
                  handleFormCategoryChange(newValue);
                }}
                value={category.category}
                renderInput={(params) => (
                  <TextField {...params} label="Select Category" />
                )}
              />
            </FormControl>

            <TextField
              label="Search by Name"
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div class="flex flex-wrap -m-4">
            {data?.map((item, i) => {
              if (item?.profile_activate_status)
                return (
                  <div
                    key={i}
                    class="w-full lg:w-1/4 p-2 cursor-pointer"
                    onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
                  >
                    <div class="relative p-2 bg-white shadow rounded-xl">
                      <img
                        class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                        src={
                          item?.profile_completion?.logo
                            ? item?.profile_completion?.logo
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                        alt=""
                      />
                      <h3 class="text-center font-semibold text-md text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.name}
                      </h3>
                      <p class="mb-3 text-center text-md text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.sub_category.charAt(0).toUpperCase() +
                          item.sub_category.slice(1, item.sub_category.length)}
                      </p>

                      {user?.following?.length != 0 ? (
                        user?.following?.find((foll) => {
                          return item?._id == foll?._id;
                        }) ? (
                          <div class="text-center ">
                            <div
                              class="relative group inline-block border py-2 px-7 font-semibold bg-[#0F3D79] text-white rounded-full transition duration-300 overflow-hidden cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                  unFollow(user?._id, { id: item?._id }, token)
                                );
                              }}
                            >
                              <span className="">
                                <CheckRoundedIcon />
                              </span>
                              <span class="relative px-3 text-lg">
                                Following
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div class="text-center">
                            <div
                              class="relative group inline-block border py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer hover:bg-[#0F3D79] hover:text-white"
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                  addFollowers(
                                    user?._id,
                                    { id: item?._id },
                                    token
                                  )
                                );
                              }}
                            >
                              <span className="">
                                <PersonAddAltRoundedIcon />
                              </span>
                              <span class="relative px-3 text-lg">Follow</span>
                            </div>
                          </div>
                        )
                      ) : (
                        <div class="text-center">
                          <div
                            class="relative group inline-block border py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer hover:bg-indigo-900 hover:text-white"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                addFollowers(
                                  user?._id,
                                  { id: item?._id },
                                  token
                                )
                              );
                            }}
                          >
                            <span className="">
                              <PersonAddAltRoundedIcon />
                            </span>
                            <span class="relative px-3 text-lg">Follow</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
