import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { keyUri } from "../../key";
import { toast } from "react-toastify";
import { InputAdornment, OutlinedInput } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import { StudentCategory } from "../../shared/constants";
import {
  fetchAllIndividualUser,
  individualUserSelector,
} from "../../api/individualUser";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/joy";

export default function Tab1({ user }) {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState();
  const [data, setData] = useState([]);
  const [debouncedText] = useDebounce(search, 1000);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { all_individual_user } = useSelector(individualUserSelector);

  useEffect(() => {
    dispatch(fetchAllIndividualUser());
  }, [dispatch, user]);

  const [category, setCategory] = useState({
    category: "",
  });

  useEffect(() => {
    setData(all_individual_user);
  }, [all_individual_user]);

  useEffect(() => {
    if (debouncedText != "") {
      if (category.category != "") {
        setData([]);
        setCategory({ category: "" });
      }
    }
  }, [debouncedText]);

  const handleFormCategoryChange = (event) => {
    ////console.log(event);
    setCategory({ category: event.value });
  };

  useEffect(() => {
    filter ? setData(filter) : setData(all_individual_user);
  }, [filter]);

  useEffect(() => {
    setData(
      all_individual_user.filter((item) => {
        return item?.profile_completion?.category == category.category;
      })
    );
  }, [category]);

  useEffect(() => {
    Axios.get(
      keyUri.BACKEND_URI + `/users/all-individual?search=${debouncedText}`
    ).then(({ data }) => {
      setFilter(data?.individualUsers);
    });
  }, [dispatch, debouncedText]);
  return (
    <div>
      <section style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div className="w-full mb-8 lg:flex justify-end">
            <FormControl className="">
              <Autocomplete
                className="mb-2 lg:mr-[15px] md:mr-[15px]"
                disablePortal
                id="combo-box-demo"
                placeholder="Select Category"
                disableClearable
                options={StudentCategory}
                name="category"
                onChange={(event, newValue) => {
                  handleFormCategoryChange(newValue);
                }}
                value={category.category}
                renderInput={(params) => (
                  <TextField {...params} label="Select Category" />
                )}
              />
            </FormControl>

            <TextField
              label="Search by Name"
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div class="flex flex-wrap -m-4">
            {data?.map((item, i) => {
              if (item?.profile_activate_status === true) {
                return (
                  <div
                    key={i}
                    class="w-full lg:w-1/4 p-2 cursor-pointer"
                    onClick={() => nav(`/profile/${item?._id}`)}
                  >
                    <div class="relative p-2 bg-white shadow rounded-xl">
                      <img
                        class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                        src={
                          item?.profile_completion?.profile_pic
                            ? item?.profile_completion?.profile_pic
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                        alt=""
                      />
                      <h3 class="text-center font-semibold text-md text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.name}
                      </h3>
                      <p class="mb-3 text-center text-md text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.profile_completion?.category}
                      </p>
                      <div class="text-center">
                        <div class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 rounded-full hover:bg-[#0F3D79] hover:text-white bg-white transition duration-300 overflow-hidden cursor-pointer">
                          <span className="">{/* <CheckRoundedIcon /> */}</span>
                          <span class="relative px-3 text-lg">
                            View Profile
                          </span>
                        </div>
                      </div>
                      {/* ------------------------------------following design--------------------------- */}
                      {/* <div class="text-center">
                    <div class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"  >
                      <span className="">
                        <CheckRoundedIcon />
                      </span>
                      <span class="relative px-3 text-lg">Following</span>
                    </div>
                  </div> */}
                      {/* ----------------------------------------------------------------------------------- */}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
