import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import { getOneAdd, exploreSelector, saveAdd, unSaveAdd } from "../../api/expore";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import AddIcon from "@material-ui/icons/Add";
import image2 from "../../images/logo.png"; // Placeholder image
import WorkIcon from "@mui/icons-material/Work";
import { RWebShare } from "react-web-share";
import ExploreIcon from "@mui/icons-material/Explore";

function ViewAds({ route }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user, token } = useSelector(ioiuserSelector);
  const { currentAdd } = useSelector(exploreSelector);
  const [isSaved, setIsSaved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getOneAdd(id)); // Fetch the ad details based on the id from the URL
    }
    if (token) {
      dispatch(fetchIOIProfile(token)); // Fetch user profile based on token
    }
  }, [id, token, dispatch]);

  const handleSaveAdd = (addId) => {
    if (user?.adds_saved?.some((saved) => saved._id === addId)) {
      dispatch(unSaveAdd(user?._id, addId, token));
    } else {
      dispatch(saveAdd(user?._id, addId, token));
    }
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Function to render media based on file format (image or video)
  const renderAdMedia = (documents) => {
    if (!documents || documents.length === 0) {
      return <p>No media available</p>; // Fallback when no documents are present
    }

    return documents.map((document, index) => {
      const isVideo = document.includes(".mp4");
      const isImage = [".jpg", ".jpeg", ".png", ".webp"].some((ext) =>
        document.includes(ext)
      );

      if (isVideo) {
        return (
          <video
            key={index}
            controls
            className="w-full h-[200px] object-cover rounded-lg my-2"
          >
            <source src={document} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      }

      if (isImage) {
        return (
          <img
            key={index}
            src={document}
            alt={`Ad media ${index}`}
            className="w-full h-[200px] object-cover rounded-lg my-2 cursor-pointer"
            onClick={() => openModal(document)}
          />
        );
      }

      return <p key={index}>Unsupported format</p>;
    });
  };

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            {/* Sidebar */}
            {route ? (
              <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
                <div className="w-full mb-6">
                  <div className="relative p-6 text-center bg-gray-100 rounded-xl">
                    <img
                      className="block mx-auto mb-5 w-28 h-28 rounded-full"
                      src={
                        user?.profile_completion?.logo
                          ? user?.profile_completion?.logo
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt=""
                    />
                    <h4 className="text-lg text-[#0F3D79] font-bold mb-3">
                      {user?.name}
                    </h4>
                    <div className="flex items-center justify-center mb-6">
                      <p className="text-md font-semibold text-gray-500">
                        {user?.sub_category}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/Conferences">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("Competitions")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Otherevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Other Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Ads">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Myevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
              </div>
            ) : (
              <></>
            )}

            {/* Main Content */}
            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      View Ad Details
                    </h2>
                    <Link to="/CreateAdd" className="w-full sm:w-auto">
                      <button
                        className="py-2 px-2 text-white font-semibold border rounded-xl bg-[#002357] w-full sm:w-auto mt-2 sm:mt-0"
                        type="button"
                      >
                        <AddIcon /> Create New Ad
                      </button>
                    </Link>
                  </div>

                  {/* Ad Content */}
                  <div className="flex flex-col lg:flex-row justify-between items-start">
                    {/* Left Content */}
                    <div className="w-full lg:w-1/2 bg-white mb-4 lg:mb-0">
                      <div className="border p-4 rounded-md">
                        <div className="flex items-center mb-4">
                          <img
                            src={
                              currentAdd?.add_posted_by_ioi?.profile_completion
                                ?.logo || image2
                            }
                            alt="Profile"
                            className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                          />
                          <div>
                            <h3 className="text-[#0F3D79] text-md font-semibold">
                              {currentAdd?.add_posted_by_ioi?.name}
                            </h3>
                            <p className="text-sm text-gray-500">
                              {currentAdd?.add_posted_by_ioi?.sub_category}
                            </p>
                          </div>
                        </div>

                        <h4 className="mt-4 text-[#0F3D79] text-md font-semibold">
                          {currentAdd?.add_title}
                        </h4>
                        <p className="mt-2 text-gray-700">
                          {currentAdd?.description}
                        </p>
                      </div>

                      <div className="flex items-center mt-4 gap-x-4">
                        <RWebShare
                          data={{
                            text: "Check out this Ad",
                            url: `http://localhost:3000/view_adds/${currentAdd._id}`,
                            title: "Ad Share",
                          }}
                        >
                          <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                            Share
                            <ShareIcon className="ml-2 text-[#346BB7]" />
                          </button>
                        </RWebShare>
                        {user?.adds_saved?.some(
                          (saved) => saved._id === currentAdd._id
                        ) ? (
                          <button
                            className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                            onClick={() => handleSaveAdd(currentAdd._id)}
                          >
                            Saved
                            <BookmarkBorderOutlinedIcon
                              className="ml-2"
                              style={{ fill: "white" }}
                            />
                          </button>
                        ) : (
                          <button
                            className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                            onClick={() => handleSaveAdd(currentAdd._id)}
                          >
                            Save
                            <BookmarkBorderOutlinedIcon
                              className="ml-2"
                              style={{ fill: "#346BB7" }}
                            />
                          </button>
                        )}
                      </div>
                    </div>

                    {/* Right Content - Images/Video Display */}
                    <div className="w-full lg:w-1/2 flex flex-col justify-start ml-2 gap-4">
                      <div className="border border-gray-300 p-2 rounded-lg">
                        {renderAdMedia(currentAdd?.document)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal for Full-Screen Image */}
          {isModalOpen && selectedImage && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
              onClick={closeModal}
            >
              <img
                src={selectedImage}
                alt="Full Screen"
                className="max-w-full max-h-full rounded-lg"
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default ViewAds;
