import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  addFollowers,
  fetchIOIProfile,
  fetchOneIoiProfile,
  ioiuserSelector,
  unFollow,
} from "../../api/ioiUser";
import { toast } from "react-toastify";
import WorkIcon from "@mui/icons-material/Work";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { fetchPostedJobs, jobsSelector } from "../../api/jobs";
import { Box, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Interweave } from "interweave";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Image from "../../images/DomesticSolutions-16.jpg";
import Image1 from "../../images/AIforAI-17.png";
import Image2 from "../../images/DreamTrue-18.jpg";
import Image3 from "../../images/broadcast.jpeg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
  minWidth: 400,
  height: 200,
  textAlign: "center",
};

function IoiProfilepage() {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const { jobs } = useSelector(jobsSelector);
  const { current_ioi_user, user, token } = useSelector(ioiuserSelector);
  const { id } = useParams();
  const classes = useStyles();
  const interweaveStyles = css`
    white-space: pre-wrap`;

  const images = [
    current_ioi_user?.profile_completion?.marketing_images[0],
    current_ioi_user?.profile_completion?.marketing_images[1],
    current_ioi_user?.profile_completion?.marketing_images[2],
    current_ioi_user?.profile_completion?.marketing_images[3],
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  useEffect(() => {
    dispatch(fetchOneIoiProfile(id));
    dispatch(fetchPostedJobs(id));
  }, [id]);

  // ---------------------------------------to slide image-----------------------------------------------
  // useEffect(() => {
  //     const interval = setInterval(nextImage, 2500); // Change image every 3 seconds (adjust as needed)

  //     return () => {
  //       clearInterval(interval); // Clear the interval when the component unmounts
  //     };
  //   }, []);
  // ----------------------------------------------------------------------------------------------------


  
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const tostPop = () => {
    toast.warning("You have already following this account", {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div>
      {
        current_ioi_user?.profile_activate_status && (
          <section class="pt-10" style={{ fontFamily: "Lato" }}>
            <div class="container mx-auto">
              <div class="flex flex-wrap">
                <div class="w-full lg:w-[25%] px-3 mt-2">
                  <div class="mb-6">
                    <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[400px]">
                      {/* <img
                        class="block mx-auto my-5 w-32 h-32 rounded-full"
                        src={
                          current_ioi_user?.profile_completion?.logo
                            ? current_ioi_user?.profile_completion?.logo
                            : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                        }
                        alt=""
                      /> */}

                      <img
                        class="block mx-auto my-5 w-32 h-32 rounded-full"
                        src={
                          current_ioi_user?.profile_completion?.logo
                            ? current_ioi_user?.profile_completion?.logo
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                        alt=""
                      />
                      {/* <img
                        class="relative w-32 h-32 mb-4 mt-4 mx-auto rounded-full object-cover object-right"
                        src={
                          current_ioi_user?.profile_completion?.logo
                            ? current_ioi_user?.profile_completion?.logo
                            : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                        }
                        alt=""
                      /> */}
                      <div class="px-6 text-center">
                        <p class="text-lg font-semibold text-[#0F3D79]">
                          {current_ioi_user?.name}
                        </p>
                        <div class="flex items-center justify-center mb-1">
                          <p class="text-sm font-semibold text-gray-500">
                            {current_ioi_user?.sub_category}
                          </p>
                        </div>
                        <div>
                          {user?.following?.length != 0 ? (
                            user?.following?.find((foll) => {
                              return current_ioi_user?._id == foll?._id;
                            }) ? (
                              <div class="text-center mt-4">
                                <div
                                  class="relative group inline-block py-2 px-7 font-semibold text-white rounded-full bg-[#0F3D79] transition duration-300 overflow-hidden cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                      unFollow(
                                        user?._id,
                                        { id: current_ioi_user?._id },
                                        token
                                      )
                                    );
                                  }}
                                >
                                  <span className="">
                                    <CheckRoundedIcon />
                                  </span>
                                  <span class="relative px-3 text-lg">
                                    Following
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div class="text-center mt-4">
                                <div
                                  class="relative group inline-block py-2 border px-7 font-semibold text-[#0F3D79] rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer hover:bg-[#0F3D79] hover:text-white"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                      addFollowers(
                                        user?._id,
                                        { id: current_ioi_user?._id },
                                        token
                                      )
                                    );
                                  }}
                                >
                                  <span>
                                    <PersonAddAltRoundedIcon />
                                  </span>
                                  <span class="relative px-3 text-lg">
                                    Follow
                                  </span>
                                </div>
                              </div>
                            )
                          ) : (
                            <div class="text-center">
                              <div
                                class="relative group inline-block py-2 border px-7 font-semibold text-[#0F3D79] rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    addFollowers(
                                      user?._id,
                                      { id: current_ioi_user?._id },
                                      token
                                    )
                                  );
                                }}
                              >
                                <span className="">
                                  <PersonAddAltRoundedIcon />
                                </span>
                                <span class="relative px-3 text-lg">
                                  Follow
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="py-1 bg-gray-100 rounded-xl overflow-hidden mb-6 hidden sm:block">
                    <div class="flex flex-wrap justify-between">
                      <h3
                        class="text-lg font-semibold text-[#0F3D79] mb-4"
                        style={{ marginLeft: "15px", marginTop: "10px" }}
                      >
                        Projects
                      </h3>
                    </div>
                    <Link
                      class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                      to="/bharc"
                    >
                      <img class="h-12 w-[135px] object-cover" src={Image3} />
                    </Link>

                    <Link
                      class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                      to="https://bharc.in/?page_id=189"
                      target="_blank"
                    >
                      <img className="h-12" src={Image} />
                    </Link>
                    <Link
                      class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                      to="https://bharc.in/?page_id=187"
                      target="_blank"
                    >
                      <img className="h-12" src={Image1} />
                    </Link>
                    <Link
                      class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                      to="https://bharc.in/?page_id=109"
                      target="_blank"
                    >
                      <img className="h-12" src={Image2} />
                    </Link>
                  </div>
                </div>
                <div class="w-full lg:w-[50%] px-3 mt-2 mb-6">
                  <div class="container mx-auto">
                    <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[270px] md:h-[400px]">
                      <div className=" border-b border-gray-200">
                        <div className="relative text-center">
                          <img
                            className="block mx-auto rounded-lg h-[270px] md:h-[400px]"
                            src={images[currentImageIndex]}
                            alt=""
                          />
                          <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
                            <button
                              className="text-[#0F3D79]"
                              onClick={prevImage}
                            >
                              <NavigateBeforeIcon />
                            </button>
                          </div>
                          <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
                            <button
                              className="text-[#0F3D79]"
                              onClick={nextImage}
                            >
                              <NavigateNextIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full mb-6 lg:mb-0 mt-5 pt-1">
                      <div className="mb-6">
                        <div className="py-4 px-6 bg-gray-100 rounded-xl h-[360px] flex flex-col">
                          <div className="flex justify-between items-center mb-4">
                            <h3
                              className="text-lg font-semibold"
                              style={{ color: "#0F3D79" }}
                            >
                              About
                            </h3>
                          </div>
                          <div
                            className="flex flex-wrap overflow-y-auto"
                            style={{
                              maxHeight: "calc(100% - 56px)",
                              overflowX: "hidden",
                            }}
                          >
                            <div className="leading-normal text-gray-500 font-medium">
                              <Interweave
                                content={
                                  current_ioi_user?.profile_completion
                                    ?.abt_company
                                }
                                customElementAttributes={{
                                  ul: { className: "custom-ul" },
                                  li: { className: "custom-li" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-[25%] px-3 ">
                  <div class="p-6 bg-gray-100 rounded-xl  h-[400px] mt-2">
                    <div class="flex flex-wrap -mx-3 mb-3 justify-between items-center">
                      <div class="w-auto px-3">
                        <h4 class="text-lg text-indigo-800 font-semibold">
                          Interested Domains
                        </h4>
                      </div>
                    </div>
                    <div class="w-full">
                      <div class="h-full rounded-xl">
                        <div class="mb-8 -mx-4">
                          <div class="-mb-3 text-start white-space-no-wrap">
                            {current_ioi_user?.profile_completion?.key_areas.map(
                              (item, i) => {
                                return (
                                  i < 6 && (
                                    <p class="px-2 pl-5 py-1 my-1 mb-1  text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                                      {item}
                                    </p>
                                  )
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-6 bg-gray-100 rounded-xl mt-4 h-[357px]">
                    <div class="flex flex-wrap -mx-3 mb-3 justify-between items-center">
                      <div class="w-auto px-3">
                        <h4 class="text-lg text-[#0F3D79] font-semibold">
                          General Information
                        </h4>
                      </div>
                    </div>

                    <div className=" flex mt-2">
                      <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                        Main Category :
                      </h4>
                      <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                        {current_ioi_user?.category?.charAt(0).toUpperCase() +
                          current_ioi_user?.category?.slice(
                            1,
                            current_ioi_user?.category.length
                          )}
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                        Establishment Year :
                      </h4>
                      <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                        {current_ioi_user?.establish_year}
                      </p>
                    </div>
                    <div className="flex mt-2">
                      <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                        Classification :
                      </h4>
                      <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                        {/* {user?.profile_completion?.classified_as} */}
                        {current_ioi_user?.profile_completion?.classified_as}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div class="flex flex-wrap w-full">
              <div class="w-full lg:w-[50%]  p-3 ">
                <div class="p-4 bg-gray-100 rounded-xl px-[10%]">
                  <h4 class="text-lg text-[#0F3D79] font-semibold py-3">
                    Additional Information
                  </h4>
                  <div className=" flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                          Authorized person:
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                          {current_ioi_user?.authorized_person_name}
                        </p>
                      </div>
                      <div className=" flex mt-2">
                        <h4 class="text-md  text-gray-500 font-medium  mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                          Designation :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                          {current_ioi_user?.designation}
                        </p>
                      </div>
                      <div className=" flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                          Email :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                          {current_ioi_user?.email}
                        </p>
                      </div>
                </div>
              </div>


              <div class="w-full lg:w-[50%]  p-3">
                <div class="p-4 bg-gray-100 rounded-xl px-[10%]">
                  <h4 class="text-lg text-[#0F3D79] font-semibold py-3">
                    Additional Information
                  </h4>
                  <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                          Headquarters :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                          {current_ioi_user?.situated_at?.city} ,{" "}
                           {current_ioi_user?.situated_at?.state}
                        </p>
                      </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Country :
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800">
                      {current_ioi_user?.situated_at?.country}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Website:
                    </h4>
                    <a
                      href={current_ioi_user?.profile_completion?.website_link}
                      target="_blank"
                      class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis
                      "
                    >
                      {current_ioi_user?.profile_completion?.website_link}
                    </a>
                  </div>
                </div>
              </div>
            </div> */}

                {/* <div class="container mx-auto"> */}
                <div class="flex-wrap bg-gray-100 rounded-xl px-[3%] w-full lg:w-[100%] m-3 mt-5">
                  <div class="flex flex-wrap  justify-between items-center">
                    <div class="w-full px-2 flex justify-between">
                      {/* <h3 class="text-lg font-semibold text-indigo-900 py-3 mt-2 mb-3"> */}
                      <h3
                        class="text-lg font-semibold py-3 mt-2 mb-3"
                        style={{ color: "#0F3D79" }}
                      >
                        Additional Information
                        {/* <Tooltip title={information}>
                        <p className="ml-2">
                          <InfoOutlinedIcon />
                        </p>
                      </Tooltip> */}
                      </h3>

                      {/* <button class="w-auto px-4 mt-4 mb-4 text-gray-300 hover:text-gray-500 ">
                    <EditIcon onClick={() => setOpen4(true)} />
                  </button> */}
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="w-full p-3 lg:w-[33.33%]">
                      <div className="flex mt-2">
                        {/* <img class="w-50 h-40" src={Image4} /> */}
                        <img
                          class="block mx-auto mb-5 w-40 h-40  rounded-full"
                          src={
                            current_ioi_user?.authorized_person_profile_pic
                              ? current_ioi_user?.authorized_person_profile_pic
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="w-full p-3 lg:w-[33.33%] mt-5">
                      <div className=" flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1">
                          Authorized person :
                        </h4>
                        <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                          {current_ioi_user?.authorized_person_name}
                        </p>
                      </div>
                      <div className=" flex mt-2">
                        <h4 class="text-md  text-gray-500 font-medium mb-1">
                          Designation :
                        </h4>
                        <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                          {current_ioi_user?.designation}
                        </p>
                      </div>
                      <div className=" flex mt-2">
                        <h4 class="text-md  text-gray-500 font-medium mb-1">
                          E-mail :
                        </h4>
                        <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                          {current_ioi_user?.email}
                        </p>
                      </div>
                      <div className=" flex mt-2">
                        <h4 class="text-md  text-gray-500 font-medium mb-1">
                          &nbsp;
                        </h4>
                        <p class="ml-2 text-sm font-semibold text-gray-800">
                          &nbsp;
                        </p>
                      </div>
                    </div>
                    <div class="w-full p-3 lg:w-[33.33%] mt-5 mb-4">
                      <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1">
                          Headquarters :
                        </h4>
                        <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                          {current_ioi_user?.situated_at?.city
                            .charAt(0)
                            .toUpperCase() +
                            current_ioi_user?.situated_at?.city.slice(1)}{" "}
                          ,{" "}
                          {current_ioi_user?.situated_at?.state
                            .charAt(0)
                            .toUpperCase() +
                            current_ioi_user?.situated_at?.state.slice(1)}{" "}
                        </p>
                      </div>

                      <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1">
                          Country:
                        </h4>
                        <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                          {current_ioi_user?.situated_at?.country}
                        </p>
                      </div>

                      <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1">
                          Website:
                        </h4>
                        <a
                          href={
                            current_ioi_user?.profile_completion?.website_link
                          }
                          target="_blank"
                          class="ml-2 mt-1 text-sm font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis"
                        >
                          {current_ioi_user?.profile_completion?.website_link}
                        </a>
                      </div>
                    </div>
                    {/*  <div class="w-full p-3 lg:w-[33.33%]">
                    <div className="flex mt-2 justify-center alignItems-center">
                      <img class="w-40 h-40" src={Image4} />
                    </div>
                  </div> */}
                  </div>
                </div>

                <div class="py-2 bg-gray-100 rounded-xl overflow-hidden mb-5 w-full h-[341px] block md:hidden">
                  <div class="flex flex-wrap justify-between">
                    <h3
                      class="text-lg font-semibold text-[#0F3D79] mb-4"
                      style={{ marginLeft: "15px", marginTop: "10px" }}
                    >
                      Projects
                    </h3>
                  </div>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/bharc"
                  >
                    <img class="h-12 w-[135px] object-cover" src={Image3} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/domesticSolutions"
                  >
                    <img class="h-12 " src={Image} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/AI"
                  >
                    <img class="h-12 " src={Image1} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/dreamTrue"
                  >
                    <img class="h-12 " src={Image2} />
                  </Link>
                </div>

                <div
                  className="w-full lg:w-[78%] px-3 relative bg-gray-100 shadow rounded-lg overflow-hidden"
                  style={{ display: type == "job" ? "block" : "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      gap: "30px",
                      flexWrap: "wrap",
                    }}
                  >
                    {jobs?.length != 0 ? (
                      <>
                        {jobs?.map((item, i) => {
                          return (
                            <Box
                              key={i}
                              sx={{
                                padding: "20px",
                                width: "25%",
                                borderRadius: "10px",
                                boxShadow: "2px 2px 2px 2px rgb(208,208,208)",
                                borderBottom: "7px solid black",
                              }}
                            >
                              <Box
                                sx={{ display: "flex", marginBottom: "20px" }}
                              >
                                <WorkIcon sx={{ fontSize: "46px" }} />
                                <Box sx={{ marginLeft: "10px" }}>
                                  <p className="text-sm">
                                    {item.job_type}&nbsp; &nbsp;{" "}
                                    {item.mode_of_work}
                                  </p>
                                  <Typography variant="h6" component="h6">
                                    {item.designation}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  marginBottom: "20px",
                                  height: "100px",
                                  width: "100%",
                                }}
                                className={classes.boxContainer}
                              >
                                <Interweave
                                  content={item.job_description}
                                  css={interweaveStyles}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div className="text-center text-3xl  text-gray-500 ">
                          <h1>No jobs posted</h1>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
        //   :
        //   <>
        //   <p className='text-center p-2 text-2xl text-red-600'>This profile has been deactivated</p>
        // </>
      }
    </div>
  );
}

export default IoiProfilepage;
