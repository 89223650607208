import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ioiuserSelector } from "../../api/ioiUser";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Image from "../../images/DomesticSolutions-16.jpg";
import Image1 from "../../images/AIforAI-17.png";
import Image2 from "../../images/DreamTrue-18.jpg";
import Image3 from "../../images/broadcast.jpeg";
import { broadcastSelector, fetchAllBroadcast } from "../../api/broadcast";
import { useEffect } from "react";
import parse from "html-react-parser";

export default function DomesticSolutions() {
  const { token, user } = useSelector(ioiuserSelector);
  const { broadcast } = useSelector(broadcastSelector);
  const [type, setType] = useState("domesticSolutions");
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    dispatch(fetchAllBroadcast("Domestic Solutions"));
  }, []);
  const isVideo = (posterImage) => posterImage?.includes(".mp4");

  const isImage = (posterImage) =>
    [".jpg", ".jpeg", ".png", ".webp"].some((ext) =>
      posterImage?.includes(ext)
    );
  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    onClick={() => nav("/home")}
                    class="block mx-auto mb-5 w-28 h-28 rounded-full cursor-pointer"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-indigo-900 font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/bharc"
                >
                  <img className="h-12 w-[150px] object-cover" src={Image3} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/domesticSolutions"
                  style={{
                    backgroundColor:
                      type == "domesticSolutions" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <img className="h-12" src={Image} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/AI"
                >
                  <img className="h-12" src={Image1} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/dreamtrue"
                >
                  <img className="h-12" src={Image2} />
                </Link>
              </div>
            </div>
            <div class="w-full lg:w-[78%] px-3 ">
              <div class="h-[67vh]  py-4 px-3 bg-gray-100 rounded-xl overflow-y-scroll">
                {broadcast?.map((item, i) => {
                  return (
                    <div key={i} class="flex flex-wrap">
                      <div class="w-full md:w-full ">
                        <div class="relative h-full">
                          <div class="flex flex-wrap ">
                            <div class="mb-6 w-full lg:w-full">
                              <div class="flex flex-wrap justify-evenly items-start">
                                {isVideo(item?.poster_image) ? (
                                  <video
                                    src={item?.poster_image}
                                    className="mt-5 w-full lg:w-[400px] h-[400px] rounded-xl object-cover"
                                    controls
                                    alt="Video"
                                  />
                                ) : isImage(item?.poster_image) ? (
                                  <img
                                    src={item?.poster_image}
                                    className="mt-5 w-full lg:w-[400px] h-[400px] rounded-xl object-cover"
                                    alt="Image"
                                  />
                                ) : (
                                  <span>Unsupported format</span>
                                )}
                                <div class="w-full lg:w-1/2">
                                  <div class="flex-1">
                                    <h3 class="mb-3 text-lg font-bold font-heading text-indigo-800  "></h3>
                                    <p className="mb-2">
                                      <span className="text-xl font-semibold  text-indigo-900">
                                        {item?.title}
                                      </span>
                                    </p>

                                    <p className="mb-2">
                                      <span className="text-l font-semibold  text-[#0F3D79]">
                                        {item?.sector}
                                      </span>
                                    </p>
                                    <p>
                                      <span className="text-sm font-normal text-gray-500">
                                        {parse(item?.description)}
                                      </span>
                                    </p>

                                    <button
                                      onClick={() => {
                                        window.location.href = `${item?.link}`;
                                      }}
                                      className="bg-[#0F3D79] text-white font-bold py-2 px-4 rounded transition duration-300 hover:bg-[#0D3366] mt-4"
                                    >
                                      Link
                                    </button>
                                  </div>

                                  {/* <div>
                                    <a target="_blank" href={item?.link}>
                                      <h4 class="mt-5 text-lg float-left font-semibold bg-gray-200 py-1 hover:bg-gray-300 rounded-lg px-4 text-indigo-900 cursor-pointer">
                                        Read More
                                      </h4>
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
