import React, { useEffect, useState } from "react";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";
import Textarea from "@mui/joy/Textarea";
import Menu from "@mui/joy/Menu";
// import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import FormatBold from "@mui/icons-material/FormatBold";
import FormatItalic from "@mui/icons-material/FormatItalic";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Check from "@mui/icons-material/Check";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/joy";
import {
  Autocomplete,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import storage from "../shared/firebaseStorage";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Avatar, ListItemIcon, FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIOIProfile,
  ioiuserSelector,
  updateIOI,
  logOut,
} from "../api/ioiUser";
import Button from "@mui/joy/Button";
import { toast } from "react-toastify";
import { Keyareas } from "../shared/constants";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import { nanoid } from "@reduxjs/toolkit";
import { Logout } from "@mui/icons-material";
import Navbar from "../components/navbar";
const columnStyles = {
  columnCount: 2,
  columnGap: "10px", // Adjust the gap as needed
  overflowX: "auto", // Enable vertical scrolling
};

const initialFormData = {
  logo: "",
  marketing_images: "",
  key_areas: "",
  abt_company: "",
};

const classification = [
  { label: "Micro", value: "Micro" },
  { label: "Small", value: "Small" },
  { label: "Medium", value: "Medium" },
  { label: "Large", value: "Large" },
];

export default function Ioiprofile() {
  const { token, user } = useSelector(ioiuserSelector);
  const dispatch = useDispatch();

  const [text, setText] = React.useState("");
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [uploadSuccessMsg, setLogoUploadSuccess] = useState("");
  const [uploadMarketSuccessMsg, setMarketUploadSuccess] = useState("");
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState(true);

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

  const [openModel, setOpenModel] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu

  const openModelFunction = () => {
    setAnchorEl(null);
    setOpenModel(true);
  };

  const handelModelClose = () => {
    setOpenModel(false);
  };
  const handleClick = (event) => {
    ////console.log("***")
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };
  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  // const deactiveAccount = () => {
  //   dispatch(deactivateOrganisation(user?._id, token));
  //   setOpenModel(false);
  // };

  // Function to toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const longText = `
  These images are available on your profile all-time. Use the images to showcase your visions, journey, milestones, awards, new announcements, future projects, and contact information. Avoid festival wishes.
  Image to be of size less than 300kb
  Only .jpg .png .webp formats allowed
  Image resolution must be 2290*697 for best view.
`;

  const Text = `
  Image to be of size less than 100kb
  Only .jpg .png .webp formats allowed
`;

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 1000) {
      setText(newText);
    }
    // else
    // setCharacterLimit('please stop typing')
  };

  const [characterLimit, setCharacterLimit] = useState(
    "Your description cannot exceed 1000 characters and above."
  );

  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  const [Logo, SetLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [logoPercent, setLogoPercent] = useState(0);
  

  
  const handleFileInputChange = (event) => {
    setLogoPercent(0);
    const newSelectedFile = event.target.files[0];
    if (newSelectedFile) {
      const isJpgOrPng =
        newSelectedFile.type === "image/jpeg" ||
        newSelectedFile.type === "image/png" ||
        newSelectedFile.type === "image/webp";

      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG/WEBP file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("Image size must be less than 100kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt100KB) {
        const storageRef = ref(
          storage,
          `/IOI Profile Completion/${nanoid() + newSelectedFile.name}`
        );
        ////console.log(storageRef);
        const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);
        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
            setLogoPercent(percent);
            if (percent == 100)
              setLogoUploadSuccess("Logo Uploaded Successfully.");
          },
          (err) => {
            console.error(err);
          },
            () => {
              // download url
              getDownloadURL(uploadFile.snapshot.ref).then((url) => {
                SetLogo(url);
                ////console.log(Logo, "setlogo");
              });
            }
        );
      }
    }
  };

  const handleDeleteClickLogo = () => {
    if (Logo) {
      const firebaseStorageURL = Logo;
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      const desertRef = ref(storage, `${fileName}`);
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
          SetLogo(null);
          setLogoUploadSuccess("");
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          ////console.log(error)
        });
    }
    SetLogo(null);
    setLogoUploadSuccess("");
  };

  const [formData, setFormData] = useState(initialFormData);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [defaultImages, setDefaultImages] = useState([]); // Initialize with an empty array
  const [imagePercent, setImagePercent] = useState(0);
  const [hideUpload, setHideUpload] = useState(false);
  const [link, setLink] = useState("");
  const [ioiclassification, setIoiClassification] = useState("");

  const handleFileInputChange1 = (event) => {
    setHideUpload(false);

    const files = event.target.files;

    // Iterate through the selected files and upload them
    Array.from(files).forEach((file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp";
      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG/WEBP file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const isLt300KB = file.size / 1024 < 300;
      if (!isLt300KB) {
        toast.warning("Image size must be less than 300kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt300KB) {
        const storageRef = ref(
          storage,
          `/IOI Profile Completion Marketing Images/${nanoid() + file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // Update progress
            setImagePercent(percent);
            setHideUpload(true);
            if (percent == 100) {
              // setHideUpload(false)
              setMarketUploadSuccess("Uploaded Successfully.");
            }
          },
          (err) => {
            //console.error(err);
          },
          () => {
            // Upload is complete, get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setSelectedFiles([...selectedFiles, downloadURL]);
            });
          }
        );
      }
    });
  };

  const handleDeleteClick = (index) => {
    if (selectedFiles.length != 0) {
      const newSelectedFiles = [...selectedFiles];
      const document = newSelectedFiles.splice(index, 1); // Remove the selected file at the given index
      const firebaseStorageURL = document[0];
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      if (fileName.includes("Default Banner Images")) {
        ////console.log("Inside if")
        setSelectedFiles(newSelectedFiles);
        setImagePercent(0);
        setHideUpload(false);
        setMarketUploadSuccess("");
      } else {
        ////console.log("inside else")
        const desertRef = ref(storage, `${fileName}`);
        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            setSelectedFiles(newSelectedFiles);
            setImagePercent(0);
            setHideUpload(false);
            setMarketUploadSuccess("");
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            ////console.log(error)
          });
      }
    } else {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles.splice(index, 1); // Remove the selected file at the given index
      setSelectedFiles(newSelectedFiles);
      setImagePercent(0);
      setHideUpload(false);
      setMarketUploadSuccess("");
    }
  };

  const clearAll = () => {
    ////console.log("clear all");

    if (selectedFiles.length !== 0) {
      const promises = [];

      //iterate over the array
      selectedFiles.forEach((firebaseStorageURL) => {
        const urlParts = firebaseStorageURL.split("?");
        const pathParts = urlParts[0].split("/");
        const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);

        // Check if the file belongs to the "Default Banner Images" folder
        if (!fileName.includes("Default Banner Images")) {
          // Perform deletion for files not in the "Default Banner Images" folder
          const desertRef = ref(storage, `${fileName}`);
          const deletePromise = deleteObject(desertRef);
          promises.push(deletePromise);
        }
      });

      Promise.all(promises)
        .then(() => {
          // All relevant files deleted successfully
          setSelectedFiles([]);
          setImagePercent(0);
          setHideUpload(false);
          setMarketUploadSuccess("");
        })
        .catch((error) => {
          // Handle any errors that occurred during deletion
          ////console.log(error);
        });
    } else {
      setSelectedFiles([]);
      setImagePercent(0);
      setHideUpload(false);
      setMarketUploadSuccess("");
    }
  };

  const [selectedkeyArea, setSelectedkeyArea] = useState([]);
  const [keyAreas, setKeyAres] = useState([]);
  const navigate = useNavigate();

  const changeHandel = (e, value) => {
    setSelectedkeyArea([...value]);
  };

  const handleKeyAreaSelection = (value) => {
    if (value.length <= 6) {
      setSelectedkeyArea([...value]);
    }
    if (value.length > 6)
      toast.warning("Your exceeded limit of 6", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  };

  const showModal = () => {
    setOpen(true);
  };
  const onFinish = (event) => {
    event.preventDefault();
    showModal();

    const data = {
      profile_completion: {
        logo: Logo,
        marketing_images:
          selectedFiles.length > 0 ? selectedFiles : defaultImages,
        key_areas: keyAreas,
        abt_company: text,
        website_link: link,
        classified_as: ioiclassification,
        status: true,
      },
    };
    dispatch(updateIOI(user?._id, data, token));
    navigate("/home");
  };

  useEffect(() => {
    if (user?.profile_completion?.marketing_images)
      setDefaultImages(user?.profile_completion?.marketing_images);
    if (user?.profile_completion?.status) navigate("/home");
  }, [user]);

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  useEffect(() => {
    const keyInterst = selectedkeyArea.map((item) => {
      return item.title;
    });
    setKeyAres(keyInterst);
  }, [selectedkeyArea]);

  return (
    <div style={{ fontFamily: "Lato" }}>
      <Navbar profile={profile} />

      <section class="bg-coolGray-50 py-4">
        <div class="container px-4 mx-auto">
          <form onSubmit={onFinish}>
            <div class="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
              <div class="pb-6 border-b border-coolGray-100">
                <div class="w-full md:w-auto text-center">
                  <h2 class="text-indigo-900 text-2xl font-semibold">
                    Profile completion
                  </h2>
                </div>
              </div>
              {/* ------------------------------------------------------logo upload------------------------------------------------------------------------ */}

              <div class="w-full md:w-auto p-2 ">
                <div className="flex">
                  <h2 class="text-indigo-900 text-lg font-semibold">
                    Upload Logo image:
                  </h2>
                  <Tooltip title={Text}>
                    <p className="ml-2">
                      <InfoOutlinedIcon />
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div className="py-3">
                <label>
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    variant="outlined"
                    color="neutral"
                  >
                    {Logo ? (
                      <>
                        <img src={Logo} className="h-36 w-36 " />
                        <button type="button" onClick={handleDeleteClickLogo}>
                          <p className="text-gray-400 hover:text-gray-700">
                            <DeleteIcon />
                          </p>
                        </button>
                      </>
                    ) : (
                      "Upload a file"
                    )}
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={handleFileInputChange}
                    />
                  </Button>
                </label>
                <>
                  {logoPercent < 100 && Logo ? (
                    <span
                      className="ml-5 font-semibold"
                      style={{ color: logoPercent < 100 ? "red" : "green" }}
                    >
                      Upload {logoPercent}%
                    </span>
                  ) : (
                    <span
                      className="ml-5 font-semibold"
                      style={{ color: "green" }}
                    >
                      {uploadSuccessMsg}
                    </span>
                  )}
                </>
              </div>

              {/* ------------------------------------------------marketing images upload----------------------------------------------------------------- */}

              <div class="w-full md:w-auto p-2 ">
                <div className="flex">
                  <h2 className="text-indigo-900 text-lg font-semibold">
                    Upload Images (max 4)
                  </h2>
                  <Tooltip title={longText}>
                    <p className="ml-2">
                      <InfoOutlinedIcon />
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div className="">
                <label>
                  {selectedFiles.length >= 4 ? (
                    <div className="flex ">
                      <span
                        className="ml-5 font-semibold"
                        style={{ color: "green" }}
                      >
                        {selectedFiles.length} files
                      </span>
                      <span
                        className="ml-5 font-semibold"
                        style={{ color: imagePercent < 100 ? "red" : "green" }}
                      >
                        Upload {imagePercent}%
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className="flex">
                        <Button
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          variant="outlined"
                          color="neutral"
                        >
                          Upload a file
                          <VisuallyHiddenInput
                            type="file"
                            accept="image/*"
                            multiple // Allow multiple file selection
                            onChange={handleFileInputChange1}
                          />
                        </Button>
                      </div>
                      {selectedFiles.length > 1 && imagePercent >= 100 ? (
                        <span
                          className="ml-5 font-semibold"
                          style={{ color: "green" }}
                        >
                          {selectedFiles.length} files
                        </span>
                      ) : (
                        imagePercent >= 100 && (
                          <span
                            className="ml-5 font-semibold"
                            style={{ color: "green" }}
                          >
                            {selectedFiles.length} file
                          </span>
                        )
                      )}

                      <>
                        {imagePercent < 100 && hideUpload ? (
                          <span
                            className="ml-5 font-semibold"
                            style={{
                              color: imagePercent < 100 ? "red" : "green",
                            }}
                          >
                            Upload {imagePercent}%
                          </span>
                        ) : (
                          <span
                            className="ml-5 font-semibold"
                            style={{ color: "green" }}
                          >
                            {uploadMarketSuccessMsg}
                          </span>
                        )}
                      </>
                    </>
                  )}
                </label>

                {/* Display the uploaded code for individual images */}
                <div className="flex flex-wrap mt-5">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="mr-10 mb-3">
                      <img src={file} className="h-40 w-40" />
                      <button
                        className="mt-2"
                        type="button"
                        onClick={() => handleDeleteClick(index)}
                      >
                        <p className="text-gray-400 hover:text-gray-700">
                          <DeleteIcon />
                        </p>
                      </button>
                    </div>
                  ))}
                </div>
                {selectedFiles.length >= 4 && (
                  <div className="w-22 p-1">
                    <button
                      onClick={clearAll}
                      className="flex flex-wrap justify-center px-4 py-1 font-medium text-md  border border-red-500 hover:border-coolGray-300 bg-white hover:bg-red-900 hover:text-white rounded-md shadow-button"
                    >
                      <p>Clear All</p>
                    </button>
                  </div>
                )}
              </div>

              {/* ---------------------------------------------classification----------------------------------------------------- */}
              <div class="w-full md:w-auto p-2">
                <h2 class="text-indigo-900 text-lg font-semibold">
                  IOI Classification
                </h2>
              </div>

              {/* <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={classification}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Classification" />}
    /> */}
              <FormControl
                sx={{ width: "100%", marginTop: "10px" }}
                size="small"
                variant="outlined"
                // required
                fullWidth
              >
                <InputLabel>Classification</InputLabel>
                <Select
                  // required
                  name="job_type"
                  label="Classification"
                  size="medium"
                  value={ioiclassification}
                  onChange={(e) => setIoiClassification(e.target.value)}
                >
                  {classification?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* ----------------------------------------------Interested domains-------------------------------------------------------------------------- */}
              <div class="mt-5 w-full md:w-auto p-2">
                <h2 class="text-indigo-900 text-lg font-semibold">
                  Interested Domains (Select any 6)
                </h2>
              </div>

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={Keyareas}
                limitTags={4}
                disableCloseOnSelect
                name={(option) => option.title}
                getOptionLabel={(option) => option.title}
                value={selectedkeyArea}
                onChange={(event, newValue) => handleKeyAreaSelection(newValue)}
                style={{ width: "100%", marginTop: "5px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Interested Domains"
                    variant="outlined"
                    // required={keyAreas.length != 0 ? false : true}
                    required={keyAreas.length > 0 ? false : true}
                  />
                )}
                PaperProps={{
                  style: {
                    ...columnStyles,
                    display: "flex",
                    flexWrap: "wrap",
                  },
                }}
                ListboxProps={{
                  style: {
                    ...columnStyles,
                  },
                }}
                renderOption={(props, option, { selected }) => {
                  const isOptionSelected = (selectedkeyArea || []).some(
                    (defaultOption) => defaultOption.title === option.title
                  );
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 2 }}
                        checked={selected || isOptionSelected}
                      />
                      {option.title}
                    </li>
                  );
                }}
              />

              {/* ----------------------------------------------------------description---------------------------------------------------------------------- */}

              <div class="w-full md:w-auto p-2 mt-5 ">
                <h2 class="text-indigo-900 text-lg font-semibold"> About:</h2>
              </div>
              {/* <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                // modules={modules}
                // formats={formats}
              /> */}
              <Textarea
                placeholder="Type here…"
                value={text}
                onChange={handleTextChange}
                minRows={2}
                maxRows={4}
                endDecorator={
                  <Box
                    sx={{
                      display: "flex",
                      gap: "var(--Textarea-paddingBlock)",
                      pt: "var(--Textarea-paddingBlock)",
                      borderTop: "1px solid",
                      borderColor: "divider",
                      flex: "auto",
                    }}
                  >
                    <Typography
                      level="body-xs"
                      sx={{
                        ml: "auto",
                        color: text.length < 1000 ? "black" : "red",
                      }}
                    >
                      {text.length < 1000
                        ? `${text.length} character(s)`
                        : characterLimit}
                    </Typography>
                  </Box>
                }
                sx={{
                  minWidth: 300,
                  fontWeight,
                  fontStyle: italic ? "italic" : "initial",
                }}
              />

              <div class="w-full md:w-auto p-2 mt-5 ">
                <h2 class="text-indigo-900 text-lg font-semibold">
                  {" "}
                  Website link:
                </h2>
              </div>

              <TextField
                fullWidth
                required
                onChange={(e) => setLink(e.target.value)}
                id="outlined-basic"
                label="Website link"
                variant="outlined"
                type="url"
              />
            </div>

            {/* --------------------------------------------submit------------------------------------------------ */}

            <div class="w-full md:w-auto p-2">
              <div class="flex flex-wrap float-right">
                <div class="w-full md:w-auto p-1.5">
                  <button
                    type="submit"
                    class="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button"
                  >
                    <p>Submit</p>
                  </button>
                </div>
              </div>
            </div>
            {/* ------------------------------------------------------------------------------------------------- */}
          </form>
        </div>
      </section>
    </div>
  );
}
