import React from "react";
import Navbar from "./navbar";
// import Footer from './footer'

const Index = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      {/* <Footer /> */}
    </>
  );
};

export default Index;
