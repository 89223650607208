import React from 'react'
import notFound from '../images/404.png'
import { NavLink } from 'react-router-dom'

function NotFound() {
  return (
    <>
      <div className='flex justify-center item-center mt-[20vh]'>
        <p className='text-4xl font-bold'>404 Page not found</p>
        <img src = {notFound} className='pt-20'/>
      </div>
      <p className='flex justify-center item-center text-2xl text-blue-500 underline'><NavLink to ='/home'>Click here to go back to home page</NavLink></p>
    </>
  )
}

export default NotFound