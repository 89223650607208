import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFollowers,
  fetchAllFollowings,
  ioiuserSelector,
  unFollow,
} from "../../api/ioiUser";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

function MyFollowings() {
  const dispatch = useDispatch();
  const { myFollowings, user, token } = useSelector(ioiuserSelector);
  const nav = useNavigate();

  useEffect(() => {
    dispatch(fetchAllFollowings(user?._id));
  }, [user]);

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div class="flex flex-wrap -m-4">
        {myFollowings?.map((item, i) => {
          if (item?.profile_activate_status)
            return (
              <div
                key={i}
                class="w-full lg:w-1/4 p-2 cursor-pointer"
                onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
              >
                <div class="relative p-2 bg-white shadow rounded-xl">
                  <img
                    class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                    src={
                      item?.profile_completion?.logo
                        ? item?.profile_completion?.logo
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.pn"
                    }
                    alt=""
                  />
                  <h3 class="text-center font-semibold text-md text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                    {item?.name}
                  </h3>
                  <p class="mb-5 text-center text-md text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                    {item?.sub_category.charAt(0).toUpperCase() +
                      item.sub_category.slice(1, item.sub_category.length)}
                  </p>
                  {user?.following?.length != 0 ? (
                    user?.following?.find((foll) => {
                      return item?._id == foll?._id;
                    }) ? (
                      <div class="text-center">
                        <div
                          class="relative group inline-block py-2 px-7 font-semibold bg-[#0F3D79] text-white  rounded-full transition duration-300 overflow-hidden cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                              unFollow(user?._id, { id: item?._id }, token)
                            );
                          }}
                        >
                          <span className="">
                            <CheckRoundedIcon />
                          </span>
                          <span class="relative px-3 text-lg">Following</span>
                        </div>
                      </div>
                    ) : (
                      <div class="text-center">
                        <div
                          class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                              addFollowers(user?._id, { id: item?._id }, token)
                            );
                          }}
                        >
                          <span className="">
                            <PersonAddAltRoundedIcon />
                          </span>
                          <span class="relative px-3 text-lg">Follow</span>
                        </div>
                      </div>
                    )
                  ) : (
                    <div class="text-center">
                      <div
                        class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            addFollowers(user?._id, { id: item?._id }, token)
                          );
                        }}
                      >
                        <span className="">
                          <PersonAddAltRoundedIcon />
                        </span>
                        <span class="relative px-3 text-lg">Follow</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
        })}
      </div>
    </div>
  );
}

export default MyFollowings;
