import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

const token = localStorage.getItem("tokenIOI")
  ? localStorage.getItem("tokenIOI")
  : null;

export const initialState = {
  all_ioi_user: [],
  current_ioi_user: [],
  follow_sug: [],
  myFollowings: [],
  recommend: [],
  isAuthenticate: token ? true : false,
  hasError: true,
  token: token,
  type: null,
  loading: false,
  user: null,
};
export const userSlice = createSlice({
  name: "ioiuser",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getAll_IOI: (state, { payload }) => {
      state.all_ioi_user = payload;
    },
    getOne_IOI: (state, { payload }) => {
      state.current_ioi_user = payload;
    },
    getUserFailure: (state) => {
      state.hasError = true;
    },
    getAllMyFollowings: (state, { payload }) => {
      state.myFollowings = payload;
    },
    getLocalStorageToken: (state, { payload }) => {
      state.token = payload.accesToken;
      state.type = payload.type;
      state.isAuthenticate = true;
    },
    isAuthenticateError: (state) => {
      state.loading = false;
      state.isAuthenticate = false;
    },
    getUserProfile: (state, { payload }) => {
      state.user = payload.user;
      state.isAuthenticate = true;
    },
    getFollowSug: (state, { payload }) => {
      state.loading = false;
      state.follow_sug = payload;
    },
    getRecommendation: (state, { payload }) => {
      state.recommend = payload;
    },
  },
});

export const {
  getUserProfile,
  isAuthenticateError,
  getLoading,
  getAll_IOI,
  getOne_IOI,
  getAllMyFollowings,
  getUserFailure,
  getAuthenticate,
  getLocalStorageToken,
  getFollowSug,
  getRecommendation,
} = userSlice.actions;

export const ioiuserSelector = (state) => state.ioiuser;
export default userSlice.reducer;

export const createIOIUser = (values) => async (dispatch) => {
  const key = "ioiuser";
  dispatch(getLoading());
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/users/validate-user",
      values,
      config
    );
    ////console.log(data, "this is ioi user data");
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      dispatch(getOne_IOI(data.indUser));
    }, 2500);
  } catch (response) {
    setTimeout(() => {
      toast.error(`${response.response.data.message}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 200);
    dispatch(getUserFailure(response));
  }
};

export const ioiLogin = (values) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/users/sign-in",
      values,
      config
    );
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: "light",
    });
    dispatch(getLocalStorageToken(data));
    localStorage.setItem("tokenIOI", data.accesToken);
  } catch (response) {
    setTimeout(() => {
      toast.error(`${response.response.data.message}`, {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        closeButton: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 200);
    dispatch(isAuthenticateError(response));
  }
};

export const fetchIOIProfile = (token) => async (dispatch) => {
  const loginConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  dispatch(getLoading());
  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + "/users/ioi-user-profile",
      loginConfig
    );
    dispatch(getUserProfile(data));
  } catch (error) {
    dispatch(isAuthenticateError(error));
    dispatch(logOut());
  }
};



export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem("tokenIOI");
    dispatch(isAuthenticateError());
    window.location.href = "/";
  } catch (error) {
    dispatch(getUserFailure(error));
  }
};

export const updateIOI = (id, values, token) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/update-ioi-user/${id}`,
      values,
      config
    );
    ////console.log(data, "this is data")
    dispatch(fetchIOIProfile(token));
    // dispatch(getUserProfile(data));
    // window.location.reload()
    //   dispatch(fetchAllverificationemail())
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const fetchFollowSugg = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/users/lets-start-following/${id}`
    );
    dispatch(getFollowSug(data.letsFollowUsers));
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const fetchRecommendation = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + `/users/recommend-ioi/${id}`
    );
    dispatch(getRecommendation(data.recommendUsers));
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const addFollowers = (id, profile, token) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/add-to-follow/${id}`,
      profile,
      config
    );

    dispatch(fetchIOIProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const unFollow = (id, profile, token) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/unfollow-ioi/${id}`,
      profile,
      config
    );
    dispatch(fetchIOIProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const fetchOneIoiProfile = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/users/get-one-ioi/${id}`,
      config
    );
    dispatch(getOne_IOI(data?.ioiUser));
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const fetchAllFollowings = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/users/ioi-following/${id}`,
      config
    );
    dispatch(getAllMyFollowings(data?.followingUsers));
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

//deactivateIOIProfile
export const deactivateOrganisation = (id, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/deactivate-ioi-account/${id}`,
      config
    );
    dispatch(fetchIOIProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      dispatch(logOut());
    }, 6000);
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
