import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { ioiuserSelector, fetchIOIProfile } from "../../api/ioiUser";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { toast } from "react-toastify";
import {
  exploreSelector,
  deleteExplore,
  fetchAllExplore,
} from "../../api/expore";

import ExploreIcon from "@mui/icons-material/Explore";

function Myevents() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { user, token } = useSelector(ioiuserSelector);
  const [searchQuery, setSearchQuery] = useState("");

  // Ensure user and explore data are available
  // useEffect(() => {
  //   if (token) {
  //     dispatch(fetchIOIProfile(token));
  //   }
  // }, [token, dispatch]);

  useEffect(() => {
    dispatch(fetchAllExplore());
  }, [dispatch]);

  const handleChangeDeleteEvents = (id) => {
    dispatch(deleteExplore(id));
  };

  const { explore } = useSelector(exploreSelector);

  // Ensure the user and explore data exist before filtering
  const filteredEvents = explore
    ?.filter((ex) => ex.explore_posted_by_ioi?._id === user?._id)
    ?.filter((ex) =>
      ex.event_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <section className="py-8" style={{ fontFamily: "Lato" }}>
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
            <div class="w-full mb-6">
              <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                <img
                  class="block mx-auto mb-5 w-28 h-28 rounded-full"
                  src={
                    user?.profile_completion?.logo
                      ? user?.profile_completion?.logo
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  alt=""
                />
                <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                  {user?.name}
                </h4>
                <div class="flex items-center justify-center mb-6">
                  <p class="text-md font-semibold text-gray-500">
                    {user?.sub_category}
                  </p>
                </div>
              </div>
            </div>
            <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
              <NavLink to="/Conferences">
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <ExploreIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Conferences
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Competitions">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <ExploreIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Competitions
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Otherevents">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <ExploreIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Other Events
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Ads">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <ExploreIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Ads
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Myevents">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  style={{
                    backgroundColor: pathname.includes("Myevents")
                      ? "#CEDDF6"
                      : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <ExploreIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      My Events
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/SavedAds">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <ExploreIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Saved Ads
                    </h4>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="w-full lg:w-[78%] px-1">
            <div className="h-full py-1 px-1 sm:px-1 bg-gray-100 rounded-xl">
              <div className="md:max-w-6xl mx-auto">
                {/* Header */}
                <div className="m-4">
                  <h2 className="text-lg font-semibold text-[#0F3D79]">
                    Explore
                    <span className="text-sm"> - My Events</span>
                  </h2>
                </div>

                {/* Search and filter section */}
                <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                  {/* Search bar */}
                  <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                    <div className="relative">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <SearchIcon className="text-gray-500" />
                      </span>
                      <input
                        type="text"
                        placeholder="Search Organisation name"
                        className="py-2 pl-10 pr-4 w-full lg:w-1/2 rounded-xl border border-gray-300"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Filter and create buttons */}
                  <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                    <button
                      className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 lg:mr-2 lg:ml-4"
                      type="button"
                    >
                      Filter
                    </button>
                    <Link to="/CreateEvent" className="w-full sm:w-auto">
                      <button
                        className="py-2 px-2 text-white font-semibold border rounded-xl bg-[#002357] w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2"
                        type="button"
                      >
                        <AddIcon /> Create Event
                      </button>
                    </Link>
                  </div>
                </div>

                {/* Events list */}
                {filteredEvents?.length > 0 ? (
                  <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredEvents.map((ex, index) => (
                      <div
                        key={index}
                        className="bg-white p-6 rounded-xl shadow-md"
                      >
                        <div className="flex justify-between items-center">
                          <div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {ex.event_name || "Event Name"}
                            </h4>
                            <p className="text-gray-600 text-sm font-semibold">
                              {new Date(ex?.date).toLocaleDateString("en-IN", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </p>
                          </div>
                          <button
                            onClick={() => handleChangeDeleteEvents(ex._id)}
                            className="ml-4 text-[#346BB7]"
                          >
                            <DeleteIcon />
                          </button>
                        </div>

                        <div className="flex justify-center items-center h-50 bg-gray-100 rounded-lg overflow-hidden mt-4">
                          <img
                            loading="lazy"
                            src={ex?.document}
                            alt="Event Image"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No events found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Myevents;
