import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RWebShare } from "react-web-share";
import {
  savePublish,
  unSavePublish,
  fetchAllPublish,
  publishSelector,
} from "../../api/publish";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList";
import AddIcon from "@material-ui/icons/Add";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { Link } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import PublishIcon from "@mui/icons-material/Publish";
import { useLocation } from "react-router-dom";

function MyPublish() {
  const { token, user } = useSelector((state) => state.ioiuser);
  const { publish } = useSelector(publishSelector); // Assuming you have the selector for published documents
  const [searchQuery, setSearchQuery] = useState("");
  const [userPosts, setUserPosts] = useState([]);
  const [ioiPosts, setIoiPosts] = useState([]);
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPublish(token)); // Fetch the published documents
  }, [dispatch, token]);

  useEffect(() => {
    if (publish) {
      // Filter user and IOI posts separately
      const userPostsFiltered = publish.filter(
        (item) => item.publish_posted_by_user
      );
      const ioiPostsFiltered = publish.filter(
        (item) => item.publish_posted_by_ioi
      );

      setUserPosts(userPostsFiltered);
      setIoiPosts(ioiPostsFiltered);
    }
  }, [publish]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSavePublish = (publishId) => {
    if (user?.publish_saved?.some((saved) => saved._id === publishId)) {
      dispatch(unSavePublish(user?._id, publishId, token));
    } else {
      dispatch(savePublish(user?._id, publishId, token));
    }
  };

  // const reloadButton = () => {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 10);
  // };

  const filteredUserPosts = userPosts.filter((post) =>
    post.publish_posted_by_user?.name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const filteredIoiPosts = ioiPosts.filter((post) =>
    post.publish_posted_by_ioi?.name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  console.log(filteredUserPosts, "filterd");

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div className="w-full mb-6">
                <div className="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    className="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo ||
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <h4 className="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div className="flex items-center justify-center mb-6">
                    <p className="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>

              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("publish")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="#">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                      General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="#">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                      Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/MyPublish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/save_publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">
  <div className="flex-grow flex items-center -mt-1">
    {/* Heading */}
    <h2 className="text-xl font-semibold text-[#0F3D79] lg:mr-6 mb-4 lg:mb-3">
      Patent Certificates
    </h2>
  </div>

  {/* Search, Filter, and Create Buttons */}
  <div className="flex flex-col lg:flex-row justify-end items-center w-full lg:w-auto">
    {/* Search Bar */}
    <div className="relative w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchIcon className="text-gray-500" />
      </span>
      <input
        type="text"
        placeholder="Search topic name"
        className="py-2 pl-10 pr-4 w-full lg:w-[300px] rounded-xl border border-gray-300"
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>

    {/* Filter Button */}
    <div className="w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
      <button
        className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
        type="button"
      >
        <FilterIcon /> Filter
      </button>
    </div>

    {/* Create Button */}
    <div className="w-full lg:w-auto">
      <Link to="/PublishCertificate" className="w-full lg:w-auto">
        <button
          className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full lg:w-auto"
          type="button"
          // onClick={reloadButton}
        >
          <AddIcon /> Create
        </button>
      </Link>
    </div>
  </div>
                   </div>


                  {/* Topic Cards */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* IOI Posts */}
                    <div className="col-span-full mt-6">
                      <p className="text-xl font-semibold text-[#0F3D79]">
                        Organisation
                      </p>
                    </div>
                    {filteredIoiPosts.length > 0 ? (
                      filteredIoiPosts.map((item) => (
                        <div
                          key={item._id}
                          className="bg-white p-6 rounded-xl shadow-md max-h-[450px] flex flex-col justify-between overflow-hidden h-[400px]"
                        >
                          <NavLink to={`/ViewCertificate/${item._id}`}>
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.publish_posted_by_ioi
                                    ?.profile_completion?.logo ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.publish_posted_by_ioi?.name || "Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.publish_posted_by_ioi?.sub_category ||
                                    "Category"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.skills[0] || "Domain Name"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item?.description.substring(0, 100)}...
                              <NavLink
                                to={`/ViewCertificate/${item._id}`}
                                className="text-blue-600"
                              >
                                Read more
                              </NavLink>
                            </p>
                            <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                              <PictureAsPdfIcon
                                style={{ fontSize: 50 }}
                                className="text-blue-600"
                              />
                            </div>
                          </NavLink>

                          {/* Save and Share Buttons */}
                          <div className="flex items-center mt-4 gap-x-4">
                            <RWebShare
                              data={{
                                text: "Check out this document",
                                url: `https://ioi.bharc.in/ViewCertificate/${item._id}`,
                                title: "Document Share",
                              }}
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>
                            {user?.publish_saved?.some(
                              (saved) => saved._id === item._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "white" }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "#346BB7" }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-span-full flex flex-col justify-center items-center">
                        <p className="mt-2">No IOI posts available</p>
                      </div>
                    )}

                    {/* User Posts */}
                    <div className="col-span-full">
                      <p className="text-xl font-semibold text-[#0F3D79]">
                        Individual
                      </p>
                    </div>
                    {filteredUserPosts?.length > 0 ? (
                      filteredUserPosts?.map((item) => (
                        <div
                          key={item._id}
                          className="bg-white p-6 rounded-xl shadow-md max-h-[450px] flex flex-col justify-between overflow-hidden h-[400px]"
                        >
                          <NavLink to={`/ViewCertificate/${item._id}`}>
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.publish_posted_by_user
                                    ?.profile_completion?.profile_pic ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.publish_posted_by_user?.name || "Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.publish_posted_by_user
                                    ?.profile_completion?.designation ||
                                    "Designation"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.certificate_name || "Topic Name"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item?.description.substring(0, 100)}...
                              <NavLink
                                to={`/ViewCertificate/${item._id}`}
                                className="text-blue-600"
                              >
                                Read more
                              </NavLink>
                            </p>
                            <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                              <PictureAsPdfIcon
                                style={{ fontSize: 50 }}
                                className="text-blue-600"
                              />
                            </div>
                          </NavLink>

                          {/* Save and Share Buttons */}
                          <div className="flex items-center mt-4 gap-x-4">
                            <RWebShare
                              data={{
                                text: "Check out this document",
                                url: `https://user.bharc.in/ViewCertificate/${item._id}`,
                                title: "Document Share",
                              }}
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>
                            {user?.publish_saved?.some(
                              (saved) => saved._id === item._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "white" }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "#346BB7" }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-span-full flex flex-col justify-center items-center">
                        <p className="mt-2">No user posts available</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyPublish;
