import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Modal from "@mui/material/Modal";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../images/comingsoon.png";
import FilterIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import image2 from "../../images/logo.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import { RWebShare } from "react-web-share";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { savePublish, unSavePublish } from "../../api/publish";
import PublishIcon from "@mui/icons-material/Publish";

function MyPublish() {
  const { token, user, connected, request_received } =
    useSelector(ioiuserSelector);
  const [publish, setPublish] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  console.log(user, "iser");

  useEffect(() => {
    if (user) {
      // Assuming user.topics is an array of topics
      const reversedPublishes = (user?.publish || []).slice().reverse(); // Reverse the array to show last added topic first
      setPublish(reversedPublishes);
    }
  }, [user]);

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  console.log(publish, "ioi publis");

  const handleSavePublish = (publishId) => {
    console.log(user?.publish_saved, "published");

    if (user?.publish_saved?.some((saved) => saved._id === publishId)) {
      dispatch(unSavePublish(user?._id, publishId, token));
    } else {
      dispatch(savePublish(user?._id, publishId, token));
    }
  };

  // const filteredPublish = publish?.filter((pub, index) => {
  //   console.log(pub, "pub");
  //   const cert = pub?.certificate_name.toLowerCase();
  //   const postedByName = user?.name.toLowerCase();
  //   const query = searchQuery.toLowerCase();
  //   return cert.includes(query) || postedByName.includes(query);
  // });

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="#">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                      General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="#">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                      Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/MyPublish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                      style={{
                        backgroundColor: pathname.includes("MyPublish")
                          ? "#CEDDF6"
                          : "#F3F4F6",
                      }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/save_publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PublishIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">
  <div className="flex-grow flex items-center -mt-1">
    {/* Heading */}
    <h2 className="text-xl font-semibold text-[#0F3D79] lg:mr-6 mb-4 lg:mb-3">
      My Publications
    </h2>
  </div>

  {/* Search, Filter, and Create Buttons */}
  <div className="flex flex-col lg:flex-row justify-end items-center w-full lg:w-auto">
    {/* Search Bar */}
    <div className="relative w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchIcon className="text-gray-500" />
      </span>
      <input
        type="text"
        placeholder="Search topic name"
        className="py-2 pl-10 pr-4 w-full lg:w-[300px] rounded-xl border border-gray-300"
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>

    {/* Filter Button */}
    <div className="w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
      <button
        className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
        type="button"
      >
        <FilterIcon /> Filter
      </button>
    </div>

    {/* Create Button */}
    <div className="w-full lg:w-auto">
      <Link to="/PublishCertificate" className="w-full lg:w-auto">
        <button
          className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full lg:w-auto"
          type="button"
          onClick={reloadButton}
        >
          <AddIcon /> Create
        </button>
      </Link>
    </div>
  </div>
</div>


                  {/* Topic Cards */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {publish?.length > 0 ? (
                      publish?.map((publish) => (
                        <div className="bg-white p-6 rounded-xl shadow-md h-[400px]">
                          <NavLink
                            key={publish?._id}
                            to={`/ViewCertificate/${publish?._id}`}
                          >
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  user?.profile_completion?.logo
                                    ? user?.profile_completion?.logo
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {user?.name}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {user?.sub_category}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {publish?.certificate_name}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {publish.description.substring(0, 100)}...{" "}
                              <a href="#" className="text-blue-600">
                                Read more
                              </a>
                            </p>
                            <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                              <PictureAsPdfIcon
                                style={{ fontSize: 50 }}
                                className="text-blue-600"
                              />
                            </div>
                          </NavLink>

                          <div className="flex items-center mt-4 gap-x-4">
                            <RWebShare
                              data={{
                                text: "Check out this Ad",
                                url: `http://ioi.bharc.in/ViewCertificate/${publish._id}`,
                                title: "Ad Share",
                              }}
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>
                            {user?.publish_saved?.some(
                              (saved) => saved._id === publish._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSavePublish(publish._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "white" }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSavePublish(publish._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "#346BB7" }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyPublish;
