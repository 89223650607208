import React from 'react'
import Conferences from './Conferences'

function index() {
  return (
    <div>
        <Conferences />
    </div>
  )
}

export default index