import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";
import { fetchIOIProfile } from "../api/ioiUser";

// Retrieve token from local storage
const token = localStorage.getItem("token") || null;

// Define the initial state
export const initialState = {
  loading: false,
  hasErrors: false,
  explore: [],
  adds: [],
  individualtoken: token,
  currentAdd: {},
};

// Redux slice for explore management
export const exploreSlice = createSlice({
  name: "explore",
  initialState,
  reducers: {
    getExploreLoading: (state) => {
      state.loading = true;
      state.hasErrors = false; // Reset error state on new request
    },
    getAllExploreSuccessful: (state, { payload }) => {
      state.loading = false;
      state.explore = payload?.all_explore || [];
      state.hasErrors = false;
    },
    getExploreFailed: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getAllAddsSuccessful: (state, { payload }) => {
      state.loading = false;
      state.adds = payload?.all_adds || [];
      state.hasErrors = false;
    },
    getAllAddFailed: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getCurrentAddSuccessful: (state, { payload }) => {
      state.loading = false;
      state.currentAdd = payload?.add || {};
      state.hasErrors = false;
    },
    getExploreSuccess: (state, { payload }) => {
      state.loading = false;
      // You might want to add a specific action for success with additional state changes if needed
    },
  },
});

// Export actions
export const {
  getExploreLoading,
  getAllExploreSuccessful,
  getAllAddsSuccessful,
  getAllAddFailed,
  getCurrentAddSuccessful,
  getExploreFailed,
  getExploreSuccess,
} = exploreSlice.actions;

// Selector to access explore state
export const exploreSelector = (state) => state.explore;

// Export reducer
export default exploreSlice.reducer;

// Thunks for API calls

// Fetch all explores
export const fetchAllExplore = () => async (dispatch) => {
  dispatch(getExploreLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/ioi-users/fetch-events`,
      config
    );
    dispatch(getAllExploreSuccessful(data));
    // toast.success(data.message, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
    console.log("Fetched all explores:", data);
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch explores.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};

// Create a new explore
export const createExplore = (value) => async (dispatch) => {
  dispatch(getExploreLoading());

  try {
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/ioi-users/create-events`,
      value,
      config
    );

    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log("Created new explore:", data);

    // Wait for 3 seconds before performing the next operation
    setTimeout(() => {
      // Perform your next operation here
      // Example: Dispatch another action or update the local state
      dispatch(getExploreSuccess(data)); // Example action dispatch
    }, 3000); // 3 seconds delay
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to create explore.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};

// Uncomment and update if needed
export const deleteExplore = (id) => async (dispatch) => {
  dispatch(getExploreLoading());
  try {
    const { data } = await axios.delete(
      `${keyUri.BACKEND_URI}/ioi-users/delete-events/${id}`,
      config
    );
    dispatch(fetchAllExplore()); // Fetch all explores after delete
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      // Perform your next operation here
      // Example: Dispatch another action or update the local state
      dispatch(getExploreSuccess(data)); // Example action dispatch
    }, 3000); // 3 seconds delay
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to delete explore.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};

// Create a new explore
export const createAdds = (value) => async (dispatch) => {
  dispatch(getExploreLoading());

  try {
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/ioi-users/create-add`,
      value,
      config
    );

    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log("Created new explore:", data);

    // Wait for 3 seconds before performing the next operation
    setTimeout(() => {
      // Perform your next operation here
      // Example: Dispatch another action or update the local state
      dispatch(getAllAddsSuccessful(data)); // Example action dispatch
    }, 3000); // 3 seconds delay
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to create explore.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getAllAddFailed());
  }
};

export const fetchAllAdds = () => async (dispatch) => {
  dispatch(getExploreLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/ioi-users/fetch-adds`,
      config
    );
    dispatch(getAllAddsSuccessful(data));
    // toast.success(data.message, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
    console.log("Fetched all explores:", data);
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch explores.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};

export const saveAdd = (userId, addId, token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/individual-users/save_add/${userId}`, // Use userId in the URL
      { addId }, // Send addId in the request body
      config // Authorization token in headers
    );

    // Dispatch actions after saving the ad
    dispatch(fetchAllAdds()); // Fetch updated ads
    dispatch(fetchIOIProfile(token)); // Fetch updated profile

    // Show success toast notification
    toast.success(data.message || "Ad saved successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    // Handle potential error messages
    const errorMessage =
      error.response?.data?.message || "Error occurred while saving the ad";
    toast.warning(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const unSaveAdd = (userId, addId, token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/individual-users/unsave_add/${userId}`,
      { addId },
      config
    );

    // Dispatch actions after unsaving the ad
    dispatch(fetchAllAdds()); // Fetch updated ads
    dispatch(fetchIOIProfile(token)); // Fetch updated profile

    // Show success toast notification
    toast.success(data.message || "Ad unsaved successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    // Handle potential error messages
    const errorMessage =
      error.response?.data?.message || "Error occurred while unsaving the ad";
    toast.warning(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const getOneAdd = (id) => async (dispatch) => {
  dispatch(getExploreLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/ioi-users/get-a-add/${id}`,
      config
    );
    dispatch(getCurrentAddSuccessful(data));
    console.log("Fetched publish data:", data);
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch publish.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};
