import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { createJobs } from "../../api/jobs";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import { MenuItem } from "@material-ui/core";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink } from "react-router-dom";

const designation = [
  { label: "Frontend Devloper", value: "Frontend Devloper" },
  { label: "Backend developer", value: "Backend developer" },
  { label: "Fullstack Developer", value: "Fullstack Developer" },
];

const JobCategory = [
  { label: "Regular Job", value: "Regular Job" },
  { label: "Research Job", value: "Research Job" },
  { label: "Internship Job", value: "Internship" },
  { label: "Fellowship Job", value: "Fellowship" },
];

const JobTypes = [
  { label: "Full time", value: "Full time" },
  { label: "Part Time", value: "Part Time" },
];

const WorkingMoods = [
  { label: "Remote", value: "Remote" },
  { label: "On Site", value: "On Site" },
  { label: "Hybrid", value: "Hybrid" },
];

function Addjobs() {
  const { token, user } = useSelector(ioiuserSelector);

  const [job, setJob] = useState({
    designation: "",
    mode_of_work: "",
    job_category: "",
    job_type: "",
    job_status: "Active",
    sub_category: "",
  });
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const dispatch = useDispatch();
  const nav = useNavigate();

  const handelChange = (e) => {
    setJob({ ...job, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  // const changeHandel = (e, value) => {
  //   setSelectedkeyArea([...value]);
  // };

  const submitHandler = (e) => {
    e.preventDefault();
    const { designation, mode_of_work, job_category, job_type, sub_category } =
      job;
    if (
      designation !== "" &&
      mode_of_work !== "" &&
      job_category !== "" &&
      job_type !== "" &&
      value !== "" &&
      value1 !== "" &&
      value2 !== ""
    ) {
      // alert('pls check code')
      const jobInfo = {
        ...job,
        job_description: value,
        job_responsibility: value1,
        job_requirement: value2,
        job_posted_by: user._id,
        sub_category: user.sub_category,
      };
      // console.log(jobInfo);
      dispatch(createJobs(jobInfo));
      nav(-1);
    } else {
      toast.warning("Please fill all the fields", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] mb-12 lg:mb-0">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              {/* <ProfileLogoInd user={user} /> */}
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/jobs">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Jobs Posted
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/internships">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Internships Posted
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/fellowships">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Fellowships Posted
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div class="w-full lg:w-[78%] px-3">
              <div class="h-full  py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <Box className="mt-10 ">
                  <form className="w-full  flex justify-center ">
                    <Grid container className="container">
                      <Grid xs={12} md={5}>
                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          variant="outlined"
                          required
                          size="small" // Add size='small' here
                        >
                          <InputLabel>Designation</InputLabel>
                          <OutlinedInput
                            required
                            name="designation"
                            label="designation"
                            value={job.designation}
                            onChange={handelChange}
                          />
                        </FormControl>

                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          size="small"
                          variant="outlined"
                          required
                          // fullWidth
                        >
                          <InputLabel>Job Type</InputLabel>
                          <Select
                            required
                            name="job_type"
                            label="Job Type"
                            size="small"
                            value={job.job_type}
                            onChange={handelChange}
                          >
                            {JobTypes.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={2}></Grid>
                      <Grid xs={12} md={5}>
                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          size="small"
                          variant="outlined"
                          required
                          // fullWidth
                        >
                          <InputLabel>Mode of working</InputLabel>
                          <Select
                            required
                            name="mode_of_work"
                            label="Mode of working"
                            size="small"
                            value={job.mode_of_work}
                            onChange={handelChange}
                          >
                            {WorkingMoods.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          size="small"
                          variant="outlined"
                          required
                          // fullWidth
                        >
                          <InputLabel>Job Category</InputLabel>
                          <Select
                            required
                            name="job_category"
                            label="Job Category"
                            size="small"
                            value={job?.job_category}
                            onChange={handelChange}
                          >
                            {JobCategory.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={4}>
                        <FormControl>
                          <div className="mt-5 font-semibold text-[#0F3D79] text-lg">
                            Job Status
                          </div>
                          <RadioGroup
                            className="py-3 ml-3"
                            row
                            name="job_status"
                            value={job.job_status}
                            onChange={handelChange}
                          >
                            <FormControlLabel
                              value="Active"
                              control={<Radio />}
                              label="Active"
                            />
                            <FormControlLabel
                              value="Inactive"
                              control={<Radio />}
                              label="Inactive"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} className="mt-4">
                        <div className="mb-3 font-semibold text-[#0F3D79] text-lg">
                          Job Description
                        </div>

                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          // modules={modules}
                          // formats={formats}
                        />
                      </Grid>
                      <Grid xs={12} className="mt-4">
                        <div className="mb-3 font-semibold text-[#0F3D79] text-lg">
                          Job Responsibility
                        </div>

                        <ReactQuill
                          theme="snow"
                          value={value1}
                          onChange={setValue1}
                        />
                      </Grid>
                      <Grid xs={12} className="mt-4">
                        <div className="mb-3 font-semibold text-[#0F3D79] text-lg">
                          Job Requirements
                        </div>

                        <ReactQuill
                          theme="snow"
                          value={value2}
                          onChange={setValue2}
                        />
                      </Grid>

                      <Box className="flex justify-end w-full">
                        <FormControl
                          sx={{ marginTop: "2%" }}
                          size="small"
                          variant="outlined"
                          required
                          // fullWidth
                        >
                          <Button
                            onClick={submitHandler}
                            type="submit"
                            variant="contained"
                            style={{
                              backgroundColor: "#0066A2",
                              color: "white",
                              padding: "5px",
                            }}
                          >
                            Submit
                          </Button>
                        </FormControl>
                      </Box>
                    </Grid>
                  </form>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Addjobs;
