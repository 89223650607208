import React from "react";
import PatentCertificate from "./PatentCertificate";

function Publishindex() {
  return (
    <div>
      <PatentCertificate />
    </div>
  );
}

export default Publishindex;
