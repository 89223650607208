import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./Jobs.css";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Modal from "@mui/material/Modal";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

function Addfellowships() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { jobs } = useSelector(jobsSelector);
  const [type, setType] = useState("jobs");
  const { user, token } = useSelector(ioiuserSelector);
  const nav = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deletionId, setDeletionId] = useState("");

  ////console.log(jobs, 'kk')
  const handleOpen = (id) => {
    setDeletionId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelDelete = () => {
    dispatch(deleteJob(deletionId, user?._id));
    setOpen(false);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  useEffect(() => {
    dispatch(fetchPostedJobs(user?._id));
  }, [user]);

  return (
    <div>
      <section style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          {/* <div className="w-full mb-8 lg:flex justify-end">
                        <FormControl className="">
                        <Autocomplete className=" mb-2 lg:mr-[15px] md:mr-[15px]"
                            disablePortal
                            id="combo-box-demo"
                            placeholder="Select Category"
                            disableClearable
                            options={sub_categories}
                            name="category"
                            onChange={(event, newValue) => {
                            handleFormCategoryChange(newValue);
                            }}
                            value={category.category}
                            renderInput={(params) => (
                            <TextField {...params} label="Select Category" />
                            )}
                        />
                        </FormControl>

                        <TextField
                        label="Search by Name"
                        size="small"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                            <InputAdornment>
                                <IconButton>
                                <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                        />
                    </div> */}
          {/* <div className="m-4 flex justify-end">
                  <Link to="/addJobs">
                    <button
                      class="py-2 px-6 w-full text-white font-semibold border  rounded-xl  bg-indigo-800 "
                      type="button"
                      onClick={reloadButton}
                    >
                      {" "}
                      <AddIcon /> Add Job
                    </button>
                  </Link>
                </div> */}
          <div class="flex flex-wrap -m-3.5  w-[100%]">
            {jobs?.map((item, i) => {
              if (item.job_category == "Fellowship") {
                return (
                  <div class="w-full md:w-1/4 p-1">
                    <div class="relative p-3 h-full bg-white border hover:border-gray-300 rounded-xl">
                      <div class="relative z-10 flex flex-col  h-full ">
                        <div class=" h-full flex flex-col justify-between ">
                          <div>
                            <div className="flex justify-between">
                              <h3 class="mb-3 text-lg font-bold font-heading text-[#0F3D79] w-[90%] ">
                                {item?.designation}
                              </h3>
                              <div
                                className="w-3 h-3 rounded-full"
                                style={{
                                  backgroundColor:
                                    item?.job_status == "Active"
                                      ? "green"
                                      : "grey",
                                }}
                              ></div>
                            </div>

                            <p className="mb-2">
                              <span className="text-gray-500">
                                <LocationOnIcon />
                              </span>
                              <span className="text-md font-medium ml-2">
                                {item?.job_type} , {item?.mode_of_work}
                              </span>
                            </p>
                            <p className="">
                              <span className="text-gray-500">
                                <WorkIcon />{" "}
                              </span>
                              <span className=" text-md font-medium ml-2">
                                {item.job_category}
                              </span>
                            </p>
                          </div>

                          <Box className="flex justify-between mt-10">
                            <Box className="cursor-pointer">
                              <Tooltip title="View applicants">
                                <Button
                                  onClick={() =>
                                    nav(`/applicants/${item?._id}`)
                                  }
                                >
                                  <PeopleAltIcon sx={{ color: "#808080" }} />
                                </Button>
                              </Tooltip>
                            </Box>
                            <Box className="cursor-pointer">
                              <Tooltip title="Edit Job">
                                <Button
                                  style={{
                                    // marginRight: "5px",
                                    color: "gray",
                                  }}
                                  onClick={() => {
                                    nav(`/editjob/${item?._id}`);
                                    reloadButton();
                                  }}
                                >
                                  <ModeEditOutlineIcon />
                                </Button>
                              </Tooltip>
                            </Box>
                            <Box>
                              <Tooltip title="Delete Job">
                                <Button
                                  style={{ color: "gray" }}
                                  onClick={() => handleOpen(item?._id)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                            </Box>
                            <>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <section class="z-40 fixed top-0 left-0 flex items-center justify-center w-full h-full bg-white bg-opacity-10">
                                  <div class="p-4">
                                    <div class="relative p-6 py-11 bg-blueGray-900 bg-opacity-30 max-w-lg text-center w-full rounded-5xl">
                                      <p class="mb-8 text-white text-2xl">
                                        Do you want to delete this job post?
                                      </p>
                                      <div class="flex flex-wrap justify-center -m-2">
                                        <div class="w-auto p-2">
                                          <a
                                            class="inline-block px-14 py-4 text-white font-semibold text-lg tracking-2xl hover:bg-gray-600 hover:text-white border rounded-full transition duration-300 cursor-pointer"
                                            onClick={handleClose}
                                          >
                                            Cancel
                                          </a>
                                        </div>
                                        <div class="w-auto p-2">
                                          <a
                                            class="inline-block px-14 py-4 font-semibold text-lg border bg-gray-100 hover:bg-red-800 text-[#0F3D79] hover:text-white rounded-full transition duration-300 cursor-pointer"
                                            onClick={() => handelDelete()}
                                          >
                                            Delete
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </Modal>
                            </>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Addfellowships;
