import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./Explore.css";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Modal from "@mui/material/Modal";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../images/comingsoon.png";

import FilterIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import image2 from "../../images/logo.png";
import image3 from "../../images/bharcimg.png";
import { exploreSelector, fetchAllExplore } from "../../api/expore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./customeDatePicker.css"; // Import the CSS file
import dayjs from "dayjs";
import { BorderColor } from "@mui/icons-material";
import utc from "dayjs/plugin/utc";
import { Menu, MenuItem } from "@mui/material";
import {
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";

import ExploreIcon from "@mui/icons-material/Explore";

function Otherevents() {
  // const { pathname } = useLocation();
  // const dispatch = useDispatch();
  // const { jobs } = useSelector(jobsSelector);
  // const [type, setType] = useState("jobs");
  // const { user, token } = useSelector(ioiuserSelector);
  // const nav = useNavigate();
  // const [open, setOpen] = React.useState(false);
  // const [deletionId, setDeletionId] = useState("");
  // const { explore } = useSelector(exploreSelector);
  // const [upcoming, setUpcoming] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");

  // const [exploredata, setExploreData] = useState({ explore });

  // ////console.log(jobs, 'kk')
  // const handleOpen = (id) => {
  //   setDeletionId(id);
  //   setOpen(true);
  // };
  // useEffect(() => {
  //   dispatch(fetchAllExplore());
  // }, []);

  // useEffect(() => {
  //   // Get the current date
  //   const currentDate = new Date();
  //   // Filter upcoming conferences based on the date, search query, and event type
  //   const filterUpcoming = explore.filter((conference) => {
  //     const conferenceDate = new Date(conference.date);
  //     const conferenceName =
  //       conference?.explore_posted_by_ioi?.name.toLowerCase() || "";
  //     const query = searchQuery.toLowerCase();

  //     // Check if the conference date is in the future, matches the search query, and is of type "Conferences"
  //     return (
  //       conferenceDate > currentDate &&
  //       conferenceName.includes(query) &&
  //       conference.event_type === "Other events"
  //     );
  //   });

  //   // Set the filtered data to the upcoming state
  //   setUpcoming(filterUpcoming);
  // }, [explore, searchQuery]);

  // useEffect(() => {
  //   // Get the current date

  //   // Filter upcoming conferences based on the date, search query, and event type
  //   const filterUpcoming = explore?.filter((conference) => {
  //     const conferenceName =
  //       conference?.explore_posted_by_ioi?.name.toLowerCase() || "";
  //     const query = searchQuery.toLowerCase();

  //     // Check if the conference date is in the future, matches the search query, and is of type "Conferences"
  //     return (
  //       conferenceName.includes(query) &&
  //       conference.event_type === "Other events"
  //     );
  //   });

  //   // Set the filtered data to the upcoming state
  //   setExploreData(filterUpcoming);
  // }, [explore, searchQuery]);

  // console.log(exploredata, "explore data");

  // const handleClose = () => setOpen(false);

  // const handelDelete = () => {
  //   dispatch(deleteJob(deletionId, user?._id));
  //   setOpen(false);
  // };

  // const reloadButton = () => {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 10);
  // };

  // useEffect(() => {
  //   dispatch(fetchIOIProfile(token));
  // }, [token]);

  // useEffect(() => {
  //   dispatch(fetchPostedJobs(user?._id));
  // }, [user]);

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { user, token } = useSelector(ioiuserSelector);
  const nav = useNavigate();
  const { explore } = useSelector(exploreSelector);
  const [upcoming, setUpcoming] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [exploreData, setExploreData] = useState({ explore });

  const [selectedDate, setSelectedDate] = useState();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEle, setAnchorEle] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState({
    sub_category: "",
  });

  const handleSubcategoryChange = (sub_category) => {
    setSelectedSubcategory({ category: sub_category?.value });
  };

  const sub_categories = [
    ...Subcategory,
    ...Subcategory2,
    ...Subcategory3,
    ...Subcategory4,
  ];

  dayjs.extend(utc);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element as the button
    setOpen(true); // Open the date picker
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  useEffect(() => {
    dispatch(fetchAllExplore());
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const [loadAllData, setLoadAllData] = useState(true); // Track initial load

  // Handle filtered data for exploreData
  useEffect(() => {
    // Check if it's the initial load to load all data
    if (loadAllData) {
      setExploreData(explore);
      setLoadAllData(false);
      return;
    }

    const query = searchQuery.toLowerCase();
    const selectedDateFormatted = selectedDate
      ? dayjs.utc(selectedDate).add(1, "day").format("YYYY-MM-DD")
      : null;

    const filterExploreData = explore?.filter((conference) => {
      const conferenceName =
        conference?.explore_posted_by_ioi?.name.toLowerCase() || "";
      const conferenceDate = dayjs.utc(conference.date).format("YYYY-MM-DD");

      const isDateMatch = selectedDateFormatted
        ? conferenceDate === selectedDateFormatted
        : true;

      const isSubcategoryMatch = selectedSubcategory.category
        ? conference?.explore_posted_by_ioi?.sub_category ===
          selectedSubcategory.category
        : true;

      return (
        conferenceName.includes(query) &&
        conference.event_type === "Other events" &&
        isDateMatch &&
        isSubcategoryMatch
      );
    });

    setExploreData(filterExploreData);
  }, [
    explore,
    searchQuery,
    selectedDate,
    selectedSubcategory.category,
    loadAllData,
  ]);

  // Handle filtered data for upcoming events
  useEffect(() => {
    if (loadAllData) return;

    const currentDate = dayjs.utc().format("YYYY-MM-DD");
    const query = searchQuery.toLowerCase();
    const selectedDateFormatted = selectedDate
      ? dayjs.utc(selectedDate).add(1, "day").format("YYYY-MM-DD")
      : null;

    const filterUpcoming = explore?.filter((conference) => {
      const conferenceName =
        conference?.explore_posted_by_ioi?.name.toLowerCase() || "";
      const conferenceDate = dayjs.utc(conference.date).format("YYYY-MM-DD");

      const isDateMatch = selectedDateFormatted
        ? conferenceDate === selectedDateFormatted
        : true;

      const isSubcategoryMatch = selectedSubcategory.category
        ? conference?.explore_posted_by_ioi?.sub_category ===
          selectedSubcategory.category
        : true;

      return (
        conferenceDate > currentDate &&
        conferenceName.includes(query) &&
        conference.event_type === "Other events" &&
        isDateMatch &&
        isSubcategoryMatch
      );
    });

    setUpcoming(filterUpcoming);
  }, [
    explore,
    searchQuery,
    selectedDate,
    selectedSubcategory.category,
    loadAllData,
  ]);

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/Conferences">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                 
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Otherevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                     style={{
                      backgroundColor: pathname.includes("Otherevents")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Other Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Ads">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Myevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            {/* <div class="w-full lg:w-[78%] px-3">
              <div class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl flex items-center justify-center">
                <img class="w-100 h-100 object-contain" src={Image1} />
              </div>
            </div> */}
            <div className="w-full lg:w-[78%] px-1">
              <div className="h-full py-1 px-1 sm:px-1 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Explore
                      <span className="text-sm"> - Other Events</span>
                    </h2>
                  </div>

                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    {/* Left-aligned search bar */}
                    <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          value={searchQuery}
                          onChange={handleSearchChange}
                          type="text"
                          placeholder="Search Organisation name"
                          className="py-2 pl-10 pr-4 w-full lg:w-3/4 rounded-xl border border-gray-300"
                        />
                      </div>
                    </div>
                    {/* Right-aligned filter and create buttons */}
                    <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      <Autocomplete
                        style={{ width: "200px" }}
                        options={sub_categories}
                        value={selectedSubcategory.sub_category}
                        onChange={(event, newValue) => {
                          handleSubcategoryChange(newValue);
                        }}
                        placeholder="Sub Category"
                      />

                      <div className="date-picker-page">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div className="flex items-center">
                            <IconButton
                              onClick={handleIconClick}
                              edge="end"
                              aria-label="open date picker"
                            >
                              <CalendarTodayIcon />
                            </IconButton>

                            <DatePicker
                              open={open}
                              onClose={() => setOpen(false)}
                              value={selectedDate}
                              onChange={(date) => {
                                setSelectedDate(date);
                                setOpen(false); // Close after selection
                              }}
                              format="DD/MM/YYYY"
                              PopperProps={{
                                anchorEl: anchorEl,
                                placement: "bottom-start", // Aligns popper at the bottom of the button
                              }}
                              slotProps={{
                                textField: { className: "hidden-input" }, // Hide the default input field
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                      </div>
                      <Link to="/CreateEvent" className="w-full sm:w-auto">
                        <button
                          className="py-2 px-2 text-white font-semibold border rounded-xl bg-[#002357] w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2"
                          type="button"
                          onClick={reloadButton}
                        >
                          <AddIcon /> Create Event
                        </button>
                      </Link>
                    </div>
                  </div>

                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3">
                    Upcoming Other Events
                  </h2>

                  {/* Topic Cards */}
                  {upcoming.length > 0 ? (
                    <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {upcoming
                        // .filter((event) => event.explore_posted_by_ioi) // Filter out events where explore_posted_by_ioi is null
                        .map((event, index) => (
                          <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-md flex flex-col h-[360px] overflow-hidden"
                          >
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  event?.explore_posted_by_ioi
                                    ?.profile_completion?.logo ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {event?.explore_posted_by_ioi?.name}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {event?.explore_posted_by_ioi?.sub_category}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {event?.event_name}
                            </h4>
                            <p className="text-gray-600 text-sm font-semibold mb-4">
                              {new Date(event?.date).toLocaleDateString(
                                "en-IN",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </p>

                            <div className="flex-grow flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden">
                              <img
                                loading="lazy"
                                src={event?.document}
                                alt="Event Image"
                                className="w-full h-full object-cover"
                              />
                            </div>
                            {/* <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                              <RWebShare
                                data={{
                                  text: "Check out this event",
                                  url: `https://ioi.bharc.in/Publish_view/${event._id}`,
                                  title: "Event Share",
                                }}
                                onClick={() =>
                                  console.log("Shared successfully!")
                                }
                              >
                                <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                  Share
                                  <ShareIcon className="ml-2 text-[#346BB7]" />
                                </button>
                              </RWebShare>

                              {user?.publish_saved?.some(
                                (saved) => saved._id === event._id
                              ) ? (
                                <button
                                  className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                  onClick={() => handleSaveTopic(event._id)}
                                >
                                  Saved
                                  <BookmarkBorderOutlinedIcon
                                    className="ml-2"
                                    style={{
                                      fill: "white",
                                    }}
                                  />
                                </button>
                              ) : (
                                <button
                                  className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                  onClick={() => handleSaveTopic(event._id)}
                                >
                                  Save
                                  <BookmarkBorderOutlinedIcon
                                    className="ml-2"
                                    style={{
                                      fill: "#346BB7",
                                    }}
                                  />
                                </button>
                              )}
                            </div> */}
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center m-5 h-[360px]">
                      <p className="mt-2 text-gray-500">
                        No Upcoming Conferences
                      </p>
                      {/* <img
                        src={nodata}
                        alt="No Data"
                        height={200}
                        width={200}
                      />
                      <p className="mt-2 text-gray-500">
                        No Upcoming Conferences
                      </p> 
                      */}
                    </div>
                  )}

                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3 mt-5">
                    All Other Events
                  </h2>

                  {exploreData.length > 0 ? (
                    <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {exploreData
                        // .filter((event) => event.explore_posted_by_ioi)
                        .filter((event) => event.event_type === "Other events") // Filter out events where explore_posted_by_ioi is null
                        .map((event, index) => (
                          <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-md flex flex-col h-[360px] overflow-hidden"
                          >
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  event?.explore_posted_by_ioi
                                    ?.profile_completion?.logo ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {event?.explore_posted_by_ioi?.name}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {event?.explore_posted_by_ioi?.sub_category}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {event?.event_name}
                            </h4>
                            <p className="text-gray-600 text-sm font-semibold mb-4">
                              {new Date(event?.date).toLocaleDateString(
                                "en-IN",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </p>

                            <div className="flex-grow flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden">
                              <img
                                loading="lazy"
                                src={event?.document}
                                alt="Description of the image"
                                className="w-full h-full object-cover"
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center m-5 h-[500px]">
                      {/* <img
                        // src={nodata}
                        alt="No Data"
                        height={200}
                        width={200}
                      /> */}
                      <p className="mt-2 text-gray-500">No All Competitions</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Otherevents;
