// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHgL4VOkjGg7nNzxxfNNYSUy15WQ8O7Hg",
  authDomain: "bharc-users-dashboard.firebaseapp.com",
  projectId: "bharc-users-dashboard",
  storageBucket: "bharc-users-dashboard.appspot.com",
  messagingSenderId: "1041581712352",
  appId: "1:1041581712352:web:34727137c7061afaf4c185",
  measurementId: "G-YWHPBW51BD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const auth = getAuth(app);
// firebase.auth().settings.appVerificationDisabledForTesting = true; // In a testing environment

export default storage