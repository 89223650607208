import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./Publish.css";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Modal from "@mui/material/Modal";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../images/comingsoon.png";

function Researchpapers() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { jobs } = useSelector(jobsSelector);
  const [type, setType] = useState("jobs");
  const { user, token } = useSelector(ioiuserSelector);
  const nav = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deletionId, setDeletionId] = useState("");

  ////console.log(jobs, 'kk')
  const handleOpen = (id) => {
    setDeletionId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelDelete = () => {
    dispatch(deleteJob(deletionId, user?._id));
    setOpen(false);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  useEffect(() => {
    dispatch(fetchPostedJobs(user?._id));
  }, [user]);

  return (
    <div>
      <section style={{ fontFamily: "Lato" }}>
        {/* <div class="container px-4 mx-auto">
                    <div class="flex flex-wrap -m-3.5">
                    {jobs?.map((item, i) => {
                        if(item.job_category == "Internship"){
                            return (
                                <div class="w-full md:w-1/4 p-1">
                                    <div class="relative p-3 h-full bg-white border hover:border-gray-300 rounded-xl">
                                        Research papers data fetch (Coming Soon, so no fetching)
                                    </div>
                                </div>
                            );
                        }
                        
                    })}
                    </div>
                </div> */}
        <div class="w-full lg:w-[78%] px-3">
              <div class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl flex items-center justify-center">
                <img class="w-100 h-100 object-contain" src={Logo} />
              </div>
            </div>
      </section>
    </div>
  );
}

export default Researchpapers;
