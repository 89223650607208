import {
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  Maincategory,
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { keyUri } from "../../key";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/joy/Button";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import storage from "../../shared/firebaseStorage";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalDialog, styled } from "@mui/joy";
import { Input, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  createverificationemail,
  updateverificationemail,
  verificationemailSelector,
} from "../../api/verifyemail";
import { createIOIUser } from "../../api/ioiUser";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Image from "../../images/bharc login-07.png";
import Logo from "../../images/logo.png";
import Modal from "@mui/joy/Modal";
import FormLabel from "@mui/joy/FormLabel";
import { nanoid } from "@reduxjs/toolkit";

const state = [
  {
    value: "India",
    label: "India",
  },
];

export default function SignIOI() {
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  const excludedEmailDomains = [
    "gmail.com",
    "outlook.com",
    "yahoo.com",
    "hotmail.com",
    "yahoo.in",
  ];

  const [formData, setFormData] = useState({
    category: "",
    sub_category: "",
    name: "",
    city: "",
    state: "",
    country: "",
    establish_year: 0,
    authorized_person_name: "",
    designation: "",
    authorized_person_profile_pic: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [category, setCategory] = useState({
    category: "",
    sub_category: "",
  });

  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [emailError, setEmailError] = useState("");
  const [flag, setFlag] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rules, setRules] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [buttondisable, setButtonDisable] = useState(true);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(0);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const [selectedFile, setSelectedFile] = useState(null);
  const [percent, setPercent] = useState(0);
  const [uploadSuccessMsg, setUploadSuccess] = useState("");
  const [document, setDocument] = useState(null);
  const { validationdetails } = useSelector(verificationemailSelector);

  const [otp, setOtp] = useState("");
  const [valMsg, setValMsg] = useState(null);
  const [allow, setAllow] = useState(true);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setOtp(validationdetails.otp);
  }, [validationdetails]);

  const verifyOtp = (e) => {
    setValMsg("");
    if (e.target.value?.length >= 4) {
      if (e.target.value == otp) {
        setValMsg("*OTP verified");
        setCount(1);
        setAllow(false);
      } else {
        setValMsg("*OTP invalid");
        setCount(count + 1);
        setAllow(true);
      }
    }
  };

  const resendOtp = (e) => {
    setCount(1);
    setValMsg("");
    setOtp("");
    setAllow(true);
    dispatch(updateverificationemail(validationdetails._id, {}));
  };

  // ----------------------firebase file upload---------------------

  const handleFileInputChange = (event) => {
    setUploadSuccess("");
    const newSelectedFile = event.target.files[0];
    setSelectedFile(newSelectedFile);
    if (newSelectedFile) {
      const isJpgOrPng =
        newSelectedFile.type === "image/jpeg" ||
        newSelectedFile.type === "image/png" ||
        newSelectedFile.type === "image/webp";
      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG/WEBP file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("Image size must be less than 100kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt100KB) {
        const storageRef = ref(
          storage,
          `/IOI Profile Registration/${nanoid() + newSelectedFile.name}`
        );
        const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);
        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
            setPercent(percent);
            if (percent == 100)
              setUploadSuccess("document uploaded successfully.");
          },
          (
              err ////console.log(err),
            ) =>
            () => {
              // download url
              getDownloadURL(uploadFile.snapshot.ref).then((url) => {
                setDocument(url);
              });
            }
        );
      }
    }
  };
  // -------------------------------------------------------

  const handleDeleteClick = () => {
    if (document) {
      const firebaseStorageURL = document;
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      const desertRef = ref(storage, `${fileName}`);
      if (desertRef) {
        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            setSelectedFile(null);
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            ////console.log(error)
          });
      }
    }
    setSelectedFile(null);
    setUploadSuccess("");
    setPercent(0);
  };

  const isInCurrentYear = (date) => date.get("year") > dayjs().get("year");
  const isPasswordValid = (password) => {
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const numbercaseRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const regex = /\s/;

    return (
      password.length >= minLength &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password) &&
      numbercaseRegex.test(password) &&
      !regex.test(password)
    );
  };

  const handleFormCategoryChange = (event) => {
    const { name, value } = event.target;
    if (name === "category" && category.sub_category !== "")
      setCategory({ sub_category: "" });

    setCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      const result = isPasswordValid(value);
      !result
        ? setRules(
            "Password must be atleast 8 characters long. Use minimum 1 special charcter, 1 number and 1 uppercase letter without any spaces"
          )
        : setRules("");
    }

    if (name === "password" && password.confirmPassword !== "") {
      setPassword({ confirmPassword: "" });
      setPasswordMismatch(false);
    }
    if (name == "confirmPassword") {
      if (password.password !== value) {
        setPasswordMismatch(true);
      } else setPasswordMismatch(false);
    }
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    // setEmailError("");
    setFlag("");

    // if(name == "email" && excludedEmailDomains.some((domain) => value.endsWith(`@${domain}`)))
    // setEmailError("This Email will not be accepted.Please enter your organisation authorised email-id")
    if (name == "email") {
      setEmail(value);
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const checkEmailAvailability = () => {
    setFlag("");
    setEmailError("");
    axios
      .get(keyUri.BACKEND_URI + `/users/check-duplicate-email/${email}`)
      .then(({ data }) => setFlag(data.message))
      .catch((error) => {
        setFlag(error.response.data.message);
        setEmailError(error.response.data.message);
      });
  };

  const handleDateChange = (event) => {
    setError(false);
    if (event == null || event == undefined) {
      setDate(0);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError(true);
    } else {
      setDate(event?.get("year"));
      setError(false);
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  ////console.log("OTP is:",otp)

  const onFinish = (event) => {
    event.preventDefault();

    // setButtonDisable(false);
    if (
      category.category === "" ||
      category.sub_category == "" ||
      formData.name == "" ||
      formData.city == "" ||
      formData.state == "" ||
      formData.country == "" ||
      date <= 0 ||
      formData.email === "" ||
      password.password == "" ||
      password.confirmPassword == "" ||
      passwordMismatch ||
      rules != "" ||
      emailError != "" ||
      error
    ) {
      // setButtonDisable(true);
      toast.error("Please fill all the details.", {
        position: "top-center",
        // autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      showModal();
      dispatch(createverificationemail(formData));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataSent = {
      type: "IOI",
      category: category.category,
      sub_category: category.sub_category,
      name: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
      situated_at: {
        city: formData.city,
        state: formData.state,
        country: formData.country,
      },
      establish_year: date,
      authorized_person_name:
        formData?.authorized_person_name?.charAt(0).toUpperCase() +
        formData?.authorized_person_name?.slice(1),
      designation:
        formData.designation.charAt(0).toUpperCase() +
        formData.designation.slice(1),
      authorized_person_profile_pic: formData?.authorized_person_profile_pic,
      email: formData?.email.toLowerCase(),
      password: password.password,
      validationId: validationdetails._id,
      documents: [document],
    };
    dispatch(createIOIUser(dataSent));
    navigation("/");
    setOpen(false);
  };

  return (
    <div>
      <section class="relative py-5 lg:py-10 overflow-hidden">
        <div class="container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4 xl:items-center">
              <div class="w-full lg:w-1/2 xl:w-3/5  order-last lg:order-first">
                <div class="relative max-w-xl mx-auto lg:mx-0 lg:max-w-3xl h-full">
                  <img
                    class="hidden md:block w-full h-166  object-cover rounded-3xl"
                    src={Image}
                    alt=""
                  />
                </div>
              </div>

              <div class="w-full lg:w-1/2 xl:w-2/5 px-4 mb-16 lg:mb-0">
                <div className="mt-5" style={{ marginLeft: "35%" }}>
                  <img class="w-32 h-18" src={Logo} alt="" />
                </div>
                <div>
                  <div className="mt-5">
                    <form onSubmit={onFinish}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Main category</InputLabel>
                        <Select
                          required
                          name="category"
                          label="Main category"
                          value={category.category}
                          onChange={handleFormCategoryChange}
                        >
                          {Maincategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ marginTop: "10px" }}
                      >
                        <InputLabel>Sub category</InputLabel>
                        <Select
                          required
                          name="sub_category"
                          label="Sub category"
                          size="small"
                          value={category.sub_category}
                          onChange={handleFormCategoryChange}
                        >
                          {category.category === "Institution"
                            ? Subcategory.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            : category.category === "Organisation"
                            ? Subcategory2.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            : category.category === "Industry"
                            ? Subcategory3.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            : category.category === "Investment"
                            ? Subcategory4.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <TextField
                          type="text"
                          size="small"
                          label="Organisation Name"
                          name="name"
                          onChange={handleFormChange}
                          required
                        />
                      </FormControl>
                      <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <TextField
                          type="text"
                          size="small"
                          label="City"
                          name="city"
                          onChange={handleFormChange}
                          required
                        />
                      </FormControl>
                      <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <TextField
                          type="text"
                          size="small"
                          label="State"
                          name="state"
                          onChange={handleFormChange}
                          required
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        required
                        margin="normal"
                        size="small"
                        style={{ marginTop: "10px" }}
                      >
                        <InputLabel>Country</InputLabel>
                        <Select
                          required
                          name="country"
                          label="Country"
                          size="small"
                          value={formData.country}
                          onChange={handleFormChange}
                        >
                          {state.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        fullWidth
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        variant="outlined"
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          required
                        >
                          <DatePicker
                            name="establish_year"
                            size="small" // Add the size="small" property here
                            label={"Year of Establishment"}
                            views={["year"]}
                            onChange={(event) => handleDateChange(event)}
                            shouldDisableYear={isInCurrentYear}
                          />
                          {error && (
                            <FormHelperText error={error}>
                              Invalid date.
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      </FormControl>

                      {/* <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <TextField
                          type="text"
                          size="small"
                          label="Authorized Person"
                          name="authorized_person_name"
                          onChange={handleFormChange}
                          // required
                        />
                      </FormControl> */}
                      {/* <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <TextField
                          type="text"
                          size="small"
                          label="Designation"
                          name="designation"
                          onChange={handleFormChange}
                          // required
                        />
                      </FormControl> */}

                      <FormControl
                        fullWidth
                        size="small"
                        style={{ marginTop: "10px" }}
                      >
                        <TextField
                          type="email"
                          size="small"
                          label="Email"
                          name="email"
                          onChange={handleFormChange}
                          onBlur={checkEmailAvailability}
                          error={Boolean(emailError) || Boolean(flag)} // Set error state based on emailError
                          helperText={
                            emailError ? emailError : flag ? flag : ""
                          }
                          required
                        />
                      </FormControl>

                      <FormControl
                        fullWidth
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          aria-describedby="outlined-password-helper-text"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          name="password"
                          onChange={handlePasswordChange}
                          required
                          error={Boolean(rules != "")}
                          // value={password.password}
                        />
                        <FormHelperText
                          id="outlined-password-helper-text"
                          style={{ color: "red" }}
                        >
                          {rules}
                        </FormHelperText>
                      </FormControl>

                      <FormControl
                        fullWidth
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-confirm-password">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-confirm-password"
                          type={showConfirmPassword ? "text" : "password"}
                          aria-describedby="outlined-password-helper-text"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm Password"
                          name="confirmPassword"
                          onChange={handlePasswordChange}
                          value={password?.confirmPassword}
                          error={Boolean(passwordMismatch)}
                          required
                          // value={password.password}
                        />
                        <FormHelperText
                          id="outlined-confirm-password-helper-text"
                          style={{ color: "red" }}
                        >
                          {passwordMismatch ? "Password doesn not match" : ""}
                        </FormHelperText>
                      </FormControl>

                      <div className="flex flex-wrap">
                        <label>
                          <Button
                            style={{ marginTop: "3%" }}
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            variant="outlined"
                            color="neutral"
                          >
                            {selectedFile ? (
                              <>
                                {selectedFile.name}
                                <button
                                  type="button"
                                  onClick={handleDeleteClick}
                                >
                                  <DeleteIcon />
                                </button>
                              </>
                            ) : (
                              <p>
                                <CloudUploadOutlinedIcon /> Upload a file
                              </p>
                            )}
                            <VisuallyHiddenInput
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={handleFileInputChange}
                            />
                          </Button>
                          <>
                            {percent < 100 && selectedFile ? (
                              <span
                                className="ml-5 font-semibold"
                                style={{
                                  color: percent < 100 ? "red" : "green",
                                }}
                              >
                                Upload {percent}%
                              </span>
                            ) : (
                              <span
                                className="ml-5 font-semibold"
                                style={{ color: "green" }}
                              >
                                {uploadSuccessMsg}
                              </span>
                            )}
                          </>
                        </label>
                      </div>
                      <p className="text-gray-700 font-medium text-sm">
                        Please upload a company id document for verification.
                      </p>

                      <Button
                        sx={{ marginTop: "15px", backgroundColor: "#0066A2" }}
                        type="submit"
                        // style={{
                        //   backgroundColor: buttondisable ? "gray" : "green",
                        // }}
                      >
                        Submit
                      </Button>
                    </form>
                  </div>

                  <div>
                    <>
                      <Modal open={open}>
                        <ModalDialog
                          aria-labelledby="basic-modal-dialog-title"
                          aria-describedby="basic-modal-dialog-description"
                          sx={{ maxWidth: 500 }}
                        >
                          <Typography id="basic-modal-dialog-title" level="h2">
                            Please enter OTP sent to your email
                          </Typography>

                          <form
                            className="mt-5"
                            onSubmit={(event) => {
                              event.preventDefault();
                              // setOpen(false);
                            }}
                          >
                            <Stack spacing={2}>
                              <FormControl>
                                <Input
                                  name="otp"
                                  autoFocus
                                  required
                                  min={4}
                                  max={4}
                                  disabled={!allow}
                                  placeholder="Enter OTP"
                                  onChange={(e) => verifyOtp(e)}
                                />
                                <div style={{}}>
                                  {valMsg && (
                                    <p
                                      style={{
                                        color:
                                          valMsg === "*OTP verified"
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {valMsg}
                                    </p>
                                  )}
                                </div>
                              </FormControl>

                              <Link
                                type="text"
                                size="small"
                                onClick={(e) => resendOtp(e)}
                              >
                                <FormLabel sx={{ cursor: "pointer" }}>
                                  Resend OTP
                                </FormLabel>
                              </Link>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                              >
                                <Button
                                  disabled={allow}
                                  onClick={(event) => handleSubmit(event)}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Stack>
                          </form>
                        </ModalDialog>
                      </Modal>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <p
        class="mx-2 sm:block md:hidden  text-center text-lg font-semibold text-indigo-900 hover:text-indigo-800"
      >
      "The application is currently supported only in a Desktop or a Laptop."
      </p> */}
      {/* <div class=" border-t border-gray-200 mt-20 md:mt-0">
        <div class="lg:flex items-center py-3 float-right md:mr-60 ">
          <div class="flex lg:mb-0 items-center">
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-indigo-900 hover:text-indigo-800"
              target="_blank"
              href="/privacy"
            >
              Privacy Policy
            </a>
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-indigo-900 hover:text-indigo-800"
              target="_blank"
              href="/agreement"
            >
              User Agreement
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}
