import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";


import { makeStyles } from "@mui/styles";


import Image from "../../images/DomesticSolutions-16.jpg";
import Image1 from "../../images/AIforAI-17.png";
import Image2 from "../../images/DreamTrue-18.jpg";
import Image3 from "../../images/broadcast.jpeg"



const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", 
    wordWrap: "break-word", 
  },
}));

export default function Projects({  }) {

  return (
 <div style={{ fontFamily: "Lato" }}>
  <div class="py-2 bg-gray-100 rounded-xl overflow-hidden mb-5 h-[341px] block md:hidden mx-5">
    <div class="flex flex-wrap justify-between">
        <h3 class="text-lg font-semibold text-[#0F3D79] mb-4" style={{ marginLeft: '15px', marginTop: '10px' }}>
            Projects
        </h3>
    </div>
    <Link 
        class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
        to="/bharc"
    >
        <img class="h-12 w-[135px] object-cover" src={Image3} />
    </Link>
    <Link 
        class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
        to="/domesticSolutions"
    >
        <img class="h-12 " src={Image} />
    </Link>
    <Link
        class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
        to="/AI"
    >
        <img class="h-12 " src={Image1} />
    </Link>
    <Link
        class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
        to="/dreamTrue"
    >
        <img class="h-12 " src={Image2} />
    </Link>
</div>

 </div>
   
  );
}
